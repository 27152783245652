import OffCanvas from 'react-aria-offcanvas';
import PropTypes from 'prop-types';
import React from 'react';
import ReduxToastr from 'react-redux-toastr';
import { connect } from 'react-redux';
import axios from 'core/assets/js/lib/tdAxios';
import { withRouter } from 'react-router-dom';

import { classificationQuestionnairesUrl } from 'accounts/urls';
import Content from 'core/assets/js/layout/placeholder/Content.jsx';
import Logger from 'core/assets/js/lib/Logger';
import MobileNavBar from 'core/assets/js/components/NavBar/MobileNavBar.jsx';
import NavBar from 'core/assets/js/components/NavBar/NavBar.jsx';
import Root from 'core/assets/js/layout/placeholder/Root.jsx';
import TopNav from 'core/assets/js/layout/placeholder/TopNav.jsx';
import UpgradeTrialBanner from 'core/assets/js/components/UpgradeTrialBanner.jsx';
import { ICON, TOASTR_DEFAULT_PROPS, USER_CARD_STATUS, URLS_WITHOUT_CHAT } from 'core/assets/js/constants';
import { ORG_SETTINGS_PAYMENTS_TABS, SETTINGS_SKILL_TABS } from 'settings/assets/js/constants';
import { PENDING_COUNT_TYPE, selectPendingCounts } from 'core/assets/js/ducks/pendingCount';
import { PERMISSIONS } from 'roles/assets/js/constants';
import {
  calculateAccountCompletePercentage, getIsBlockedByGetStarted,
} from 'accounts/assets/js/lib/helpers';
import { contactsUrl } from 'contacts/urls';
import { documentsUrl } from 'documents/urls';
import ChatTDLive from 'accounts/assets/js/ChatTDLive.jsx';
import {
  financeExpensesUrl,
  financeInvoicesUrl,
  financeManagersBudgetUrl,
  financeProFormaInvoicesUrl,
  financeWorksheetsUrl,
  finance1099FilingsUrl,
} from 'finance/urls';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { interviewListUrl } from 'interviews/urls';
import { isSSR } from 'core/assets/js/config/checks';
import { importsListUrl } from 'importer/urls';
import {
  myTasksListUrl,
  projectInvitationsUrl,
  projectListUrl,
  projectOpportunitiesUrl,
  timeTrackerUrl,
} from 'projects/urls';
import {
  notificationSettingsUrl,
  orgSettingsApiKeysManageUrl,
  orgSettingsEmailTemplatesUrl,
  orgSettingsFinControllerUrl,
  orgSettingsFinanceUrl,
  orgSettingsGeneralUrl,
  orgSettingsPaymentsUrl,
  orgSettingsPrivacyUrl,
  orgSettingsProjectsUrl,
  orgSettingsSkillsUrl,
  orgSettingsTaxUrl,
  orgSettingsUrl,
  orgTemplatesManageUrl,
} from 'settings/urls';
import { orgDashboardUrl } from 'organizations/urls';
import { orgIntegrationsUrl } from 'integrations/urls';
import {
  orgPeopleDiscoverUrl,
  orgPeopleInviteesUrl,
  orgPeopleManagersUrl,
  orgPeopleMessagingUrl,
  orgPeopleProvidersUrl,
  userGroupsUrl,
  getChatUnreadCountUrl,
} from 'people/urls';
import { orgSettingsRatesUrl } from 'rates/urls';
import { orgSpec, userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { reportsUrl } from 'analytics/urls';
import { rolesListUrl } from 'roles/urls';
import {
  getActiveUserCardPermissionChecker,
  selectActiveOrg,
  selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import {
  selectIsOnTrial,
  selectIsTrialExpired,
  selectTrialSubscription,
} from 'organizations/assets/js/reducers/subscriptions';
import { selectSetupSteps } from 'accounts/assets/js/ducks/account';
import { DOCUMENT_GET_ELEMENT_BY_ID, DOCUMENT_QUERY_SELECTOR } from 'core/assets/js/config/settings';
import ServiceOrderCutoffReminder from 'core/assets/js/components/ServiceOrderCutoffReminder.jsx';
import SiteWideBannersProvider from 'core/assets/js/components/SiteWideBannersProvider.jsx';
import { getViewState } from 'core/assets/js/ducks/view';
import TimeTrackerUnused from 'projects/assets/js/components/timeTracker/TimeTrackerUnused.jsx';
import { fetchTimeTrackerUnusedRecordsDS } from 'projects/assets/js/data-services/view';

const timeTrackerComponentName = TimeTrackerUnused.GetComponentName();

class LayoutDefault extends React.Component {
  static getDerivedStateFromProps(props, state) {
    const body = DOCUMENT_QUERY_SELECTOR('body');
    if (body && URLS_WITHOUT_CHAT.findIndex(url => props.location.pathname.includes(url)) >= 0  && ![...body.classList].includes('no-live-chat')) {
      body.classList.add('no-live-chat');
    }
    if (body && URLS_WITHOUT_CHAT.findIndex(url => props.location.pathname.includes(url)) < 0  && [...body.classList].includes('no-live-chat')) {
      body.classList.remove('no-live-chat');
    }
    if (props.location && state.pathname !== props.location.pathname) {
      // Remove inline styling on body element when changing paths.
      if (!isSSR) {
        body.removeAttribute('style');
      }
      return {
        ...state,
        pathname: props.location.pathname,
        isMenuOpened: false,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);

    const { location } = props;
    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getIsTimeTrackerEnabled = this.getIsTimeTrackerEnabled.bind(this);
    this.state = {
      isMenuOpened: false,
      pathname: location && location.pathname,
      updatedCount: false,
      unreadCount: 0,
    };
    this.logger = new Logger('layout-default');
    this.logger.log('created');
  }

  getIsTimeTrackerEnabled() {
    const { activeOrg, hasOrgAccess } = this.props;
    const isProvider = hasOrgAccess({ requireProvider: true });
    return activeOrg.time_tracker_enabled && isProvider;
  }

  componentDidMount() {
    // Hide hubspot chat
    if (!isSSR) {
      const containerEl = DOCUMENT_GET_ELEMENT_BY_ID('hubspot-messages-iframe-container');
      if (containerEl) {
        containerEl.classList.add('hide');
        containerEl.classList.remove('show');
      }
    }
    const { activeOrg, dispatch } = this.props;
    this.getUnreadCount(activeOrg.alias);
    if (this.getIsTimeTrackerEnabled()) {
      dispatch(fetchTimeTrackerUnusedRecordsDS({
        componentName: timeTrackerComponentName, orgAlias: activeOrg.alias,
      }));
    }
  }

  toggleMenu() {
    const { isMenuOpened } = this.state;
    this.setState({
      isMenuOpened: !isMenuOpened,
    });
  }

  handleClose() {
    this.setState({
      isMenuOpened: false,
    }, () => {
      if (!isSSR) {
        // Remove inline styling on body element when changing paths.
        DOCUMENT_QUERY_SELECTOR('body').removeAttribute('style');
      }
    });
  }

  componentDidUpdate(__props, state) {
    const { activeOrg } = this.props;
    const { updatedCount } = this.state;
    if (state.updatedCount !== updatedCount) {
      this.getUnreadCount(activeOrg.alias);
    }
  }

  async getUnreadCount(orgAlias) {
    const { activeOrg } = this.props;
    const showMessagingHub = activeOrg.message_hub_enabled;
    if (!showMessagingHub) {
      return;
    }
    const { data } = await axios.get(getChatUnreadCountUrl({ orgAlias }));
    this.setState({
      unreadCount: data.unreadCount,
      updatedCount: false,
    });
  }

  getEvents() {
    const chatEvents = {
      'event-chat-change': () => this.setState({ updatedCount: true }),
    };
    return chatEvents;
  }

  render() {
    const {
      activeOrg,
      activeUserCard: { isEmployee, status, user },
      children,
      hasOrgAccess,
      hasPermission,
      isManager,
      isOnTrial,
      isTrialExpired,
      pendingCounts,
      steps,
      timeTrackerUnused,
      trialSubscription,
    } = this.props;
    const showTrialUpgradeBanner = isManager && trialSubscription && isOnTrial && !isTrialExpired;

    const { isMenuOpened, unreadCount } = this.state;
    const orgAlias = activeOrg.unique_alias;

    const isAnyManager = hasOrgAccess({ requireManager: true });
    const isHigherManager = hasOrgAccess({ requireOrgCreator: true, requireFinController: true });
    const isOrgCreator = hasOrgAccess({ requireOrgCreator: true });
    const isDeactivatedInOrg = status === USER_CARD_STATUS.DEACTIVATED;

    // Show invoicing section to:
    //  - any manager with CAN_VIEW_INVOICES permission
    //  - providers
    //  - contractor managers
    const showInvoicing = hasPermission(PERMISSIONS.CAN_VIEW_INVOICES)
      || !isEmployee || !isAnyManager;
    const billingMenuIsEnabled = false;
    const showBilling = isHigherManager && billingMenuIsEnabled;
    const canViewProviders = (
      isAnyManager || activeOrg.should_provider_view_other_providers
    );
    const accountCompletePercentage = calculateAccountCompletePercentage(steps, activeOrg.alias);

    const isDraftInvoiceEnabled = activeOrg.allow_draft_invoice;

    const isApiKeyAccessEnabled = activeOrg.api_access_enabled;
    const isIntegrationsEnabled = activeOrg.integrations_enabled;
    const isDocumentsEnabled = activeOrg.documents_enabled;
    const isRolesEnabled = activeOrg.roles_enabled;
    const canViewManagers = isAnyManager || activeOrg.providers_can_see_managers;
    const hasManageRolesPermission = hasPermission(PERMISSIONS.CAN_MANAGE_ROLES);
    const showMessagingHub = activeOrg.message_hub_enabled;
    const canAccessReports = hasPermission(PERMISSIONS.CAN_ACCESS_REPORTS);
    let showInviteesMenuItem;
    if (activeOrg.roles_enabled) {
      showInviteesMenuItem = hasPermission(PERMISSIONS.CAN_INVITE_PROVIDERS)
        || hasPermission(PERMISSIONS.CAN_INVITE_MANAGERS);
    } else {
      showInviteesMenuItem = isAnyManager;
    }

    // Projects items
    const projectSubMenuItems = [
      {
        label: 'My projects',
        path: projectListUrl(orgAlias),
        regEx: [
          RegExp('/projects/[0-9]+/[a-zA-Z]+'),
        ],
      },
      {
        label: 'My tasks',
        path: myTasksListUrl(orgAlias),
      },
    ];

    if (activeOrg.project_opportunities_enabled) {
      projectSubMenuItems.push({
        label: 'Opportunities',
        sections: [
          '/projects/opportunities',
        ],
        regEx: [
          RegExp('[A-z]+\\/projects\\/[0-9]+\\/apply'),
          RegExp('[A-z]+\\/projects\\/[0-9]+\\/applications\\/[0-9]'),
        ],
        path: projectOpportunitiesUrl(orgAlias),
      });
    }

    projectSubMenuItems.push({
      count: [
        PENDING_COUNT_TYPE.PROJECT_INVITATIONS,
        PENDING_COUNT_TYPE.TASK_INVITATIONS,
        PENDING_COUNT_TYPE.OPPORTUNITY_INVITATIONS,
      ].reduce(
        (acc, type) => {
          const { count } = pendingCounts[type] || {};
          if (count) {
            return acc + count;
          }
          return acc;
        },
        0,
      ),
      label: 'Invitations',
      path: projectInvitationsUrl(orgAlias),
      sections: ['/projects/invitations'],
    });

    // Settings items
    const settingsSubItems = [{
      label: 'Organization',
      path: orgSettingsUrl(orgAlias),
    }];

    const canManageOrgSettings = hasPermission(PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS);
    const canManagePaymentsSettings = hasPermission(PERMISSIONS.CAN_MANAGE_PAYMENTS_SETTINGS);

    if (canManageOrgSettings) {
      settingsSubItems.push({
        label: 'General settings',
        path: orgSettingsGeneralUrl(orgAlias),
        sections: [orgSettingsGeneralUrl(orgAlias)],
      });
    }

    // Same logic as CompanyDetailsStep.isApplicable, but for this specific organization
    const showYourPaymentsLink = !isEmployee;
    if (showYourPaymentsLink) {
      settingsSubItems.push({
        label: canManageOrgSettings ? 'Your payments' : 'Payments',
        path: orgSettingsPaymentsUrl(orgAlias, ORG_SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS),
        sections: [`${orgAlias}/org-member/settings/payments`],
      });
    }

    if (!isOrgCreator) {
      settingsSubItems.push({
        label: 'Rates',
        path: orgSettingsRatesUrl(orgAlias),
        sections: ['/rates'],
      });
    }

    if (canManagePaymentsSettings) {
      settingsSubItems.push({
        label: showYourPaymentsLink ? 'Organization payments' : 'Payments',
        path: orgSettingsFinanceUrl(orgAlias),
        sections: [`/${orgAlias}/settings/payments`],
      });
    }

    if (canManageOrgSettings) {
      settingsSubItems.push(
        {
          label: 'Projects & Tasks',
          path: orgSettingsProjectsUrl(orgAlias),
          sections: [`/${orgAlias}/settings/projects`],
        },
        {
          label: 'Financial Controller',
          path: orgSettingsFinControllerUrl(orgAlias),
          sections: [orgSettingsFinControllerUrl(orgAlias)],
        },
        {
          label: 'Privacy',
          path: orgSettingsPrivacyUrl(orgAlias),
          sections: [orgSettingsPrivacyUrl(orgAlias)],
        },
        {
          label: 'Custom skills',
          path: orgSettingsSkillsUrl(orgAlias, SETTINGS_SKILL_TABS.SKILLS),
          sections: [`${orgAlias}/settings/skills`],
        },
      );
    }

    if (isAnyManager) {
      settingsSubItems.push({
        label: 'Onboarding Forms',
        path: interviewListUrl(orgAlias),
        sections: ['/interviews'],
      });

      if (activeOrg.bulk_import_enabled)  {
        settingsSubItems.push({
          label: 'Imports',
          path: importsListUrl(orgAlias),
          sections: ['/imports'],
        });
      }
    }

    if (isRolesEnabled && hasManageRolesPermission) {
      settingsSubItems.push({
        label: 'Roles & Permissions',
        path: rolesListUrl(orgAlias),
        sections: ['/roles'],
      });
    }

    settingsSubItems.push({
      label: 'Notifications',
      path: notificationSettingsUrl(orgAlias),
    });

    if (isAnyManager) {
      settingsSubItems.push({
        label: 'Templates',
        path: orgTemplatesManageUrl(orgAlias),
        sections: ['/settings/templates'],
      });
    }

    if (canManageOrgSettings) {
      if (isIntegrationsEnabled) {
        settingsSubItems.push({
          label: 'Integrations',
          path: orgIntegrationsUrl(orgAlias),
          sections: ['/settings/integrations'],
        });
      }
      if (isApiKeyAccessEnabled) {
        settingsSubItems.push({
          label: 'API',
          path: orgSettingsApiKeysManageUrl(orgAlias),
          sections: ['/settings/api-access'],
        });
      }
      settingsSubItems.push({
        label: 'Tax',
        path: orgSettingsTaxUrl(orgAlias),
        sections: ['/settings/tax'],
      });
      if (activeOrg.can_customize_notifications) {
        settingsSubItems.push({
          label: 'Email Templates',
          path: orgSettingsEmailTemplatesUrl(orgAlias),
          sections: ['/settings/email-templates'],
        });
      }
    }

    const navbarItems = [
      {
        path: orgDashboardUrl(orgAlias),
        label: 'Dashboard',
        icon: ICON.DASHBOARD,
        sections: [orgDashboardUrl(orgAlias)],
      },
      {
        path: projectListUrl(orgAlias),
        label: 'Projects',
        icon: ICON.PROJECTS,
        sections: [`${orgAlias}/projects`, `${orgAlias}/my-tasks`],
        items: projectSubMenuItems,
      },
      {
        path: isAnyManager ? financeManagersBudgetUrl(orgAlias) : financeWorksheetsUrl(orgAlias),
        label: 'Finances',
        icon: ICON.FINANCE,
        sections: ['/finance/'],
        items: [
          isAnyManager ? {
            label: 'Budgets',
            sections: ['/finance/managers-budget'],
            path: financeManagersBudgetUrl(orgAlias),
          } : null,
          {
            sections: ['/finance/worksheets'],
            label: 'Worksheets',
            path: financeWorksheetsUrl(orgAlias),
            count: pendingCounts[PENDING_COUNT_TYPE.WORKSHEETS].count,
          },
          {
            sections: ['/finance/expenses'],
            label: 'Expenses',
            path: financeExpensesUrl(orgAlias),
            count: pendingCounts[PENDING_COUNT_TYPE.EXPENSES].count,
          },
          showInvoicing ? {
            sections: ['/finance/invoices'],
            label: 'Invoices',
            path: financeInvoicesUrl(orgAlias),
            count: pendingCounts[PENDING_COUNT_TYPE.INVOICES].count,
          } : null,
          isDraftInvoiceEnabled ? {
            sections: ['/finance/proforma-invoices'],
            label: 'Proforma Invoices',
            path: financeProFormaInvoicesUrl(orgAlias),
            count: pendingCounts[PENDING_COUNT_TYPE.PRO_FORMA_INVOICES].count,
          } : null,
        ],
      },
    ];

    if (this.getIsTimeTrackerEnabled()) {
      const hasInProgressWorkTimeBlock = (
        (
          Array.isArray(timeTrackerUnused.withoutTask)
          && timeTrackerUnused.withoutTask.some(wtb => !wtb.endTime)
        )
        || (
          Array.isArray(timeTrackerUnused.projects)
          && timeTrackerUnused.projects.some(p => p.workTimeBlocks.some(wtb => !wtb.endTime))
        )
      );
      navbarItems.push({
        icon: ICON.TIMER,
        label: 'Time tracker',
        path: timeTrackerUrl(orgAlias),
        sections: ['/time-tracker'],
        suffix: !hasInProgressWorkTimeBlock ? null : (
          <div className="time-tracker-active float-right px-2 d-inline-block">Active</div>
        ),
      });
    }

    const teamUrl = canViewProviders
      ? orgPeopleProvidersUrl(orgAlias)
      : orgPeopleManagersUrl(orgAlias);

    if (canViewProviders || canViewManagers) {
      navbarItems.splice(1, 0, {
        path: teamUrl,
        label: 'Teams',
        icon: ICON.PEOPLE,
        sections: ['/people', '/contact', '/team'],
        items: [
          {
            sections: [
              '/team/providers',
              '/team/managers',
              '/people/providers',
              '/people/managers',
              '/people/invite-providers',
              '/people/invite-managers',
            ],
            label: 'Team',
            path: teamUrl,
          },
          isAnyManager ? {
            label: 'Groups',
            sections: ['/people/user-group'],
            path: userGroupsUrl(orgAlias),
          } : null,
          isAnyManager ? {
            label: 'Contacts',
            sections: ['/contact'],
            path: contactsUrl(orgAlias),
          } : null,
          showInviteesMenuItem ? {
            label: 'Invitees',
            sections: ['/people/invitees'],
            path: orgPeopleInviteesUrl(orgAlias),
            count: pendingCounts[PENDING_COUNT_TYPE.ORG_INVITEES].count,
          } : null,
        ],
      });
    }

    if (showMessagingHub) {
      navbarItems.splice(1, 0, {
        label: 'Messaging',
        icon: ICON.COMMENTS,
        sections: ['/messaging'],
        path: orgPeopleMessagingUrl(orgAlias),
        count: unreadCount,
      });
    }

    if (isAnyManager) {
      navbarItems.push({
        icon: ICON.STARS,
        label: 'TalentMarket',
        path: orgPeopleDiscoverUrl(orgAlias),
        sections: [orgPeopleDiscoverUrl(orgAlias)],
      });
    }

    if (canAccessReports) {
      navbarItems.push({
        path: reportsUrl(orgAlias),
        label: 'Reports',
        icon: ICON.CHART_BAR,
        sections: [reportsUrl(orgAlias)],
      });
    }

    const legalItems = [];
    if (isAnyManager && isDocumentsEnabled) {
      legalItems.push({
        label: 'Contract Templates',
        path: documentsUrl(orgAlias),
        sections: [documentsUrl(orgAlias)],
      });
    }
    if (
      hasPermission(PERMISSIONS.CAN_REVIEW_IRS_TAXPAYER_INFORMATION_FORMS)
      || (
        activeOrg['1099_filings_enabled']
        && (
          hasPermission(PERMISSIONS.CAN_FILE_1099_REPORTS)
          || hasPermission(PERMISSIONS.CAN_VIEW_1099_CONTRACTORS)
        )
      )
    ) {
      legalItems.push({
        label: 'Taxation',
        path: finance1099FilingsUrl(orgAlias),
        sections: [finance1099FilingsUrl(orgAlias)],
      });
    }
    if (
      hasPermission(PERMISSIONS.CAN_VIEW_CLASSIFICATION_QUESTIONNAIRE_SUBMISSIONS)
      && activeOrg.aor_classification_questionnaire_enabled
    ) {
      legalItems.push({
        label: 'Classifications',
        path: classificationQuestionnairesUrl(orgAlias),
        sections: [classificationQuestionnairesUrl(orgAlias)],
      });
    }

    if (legalItems.length > 0) {
      navbarItems.push({
        icon: ICON.SCALE,
        label: 'Legal',
        path: documentsUrl(orgAlias),
        sections: [documentsUrl(orgAlias)],
        items: legalItems,
      });
    }

    const showAccountProgressBar = !isDeactivatedInOrg && accountCompletePercentage < 100;
    const isBlockedByGetStarted = getIsBlockedByGetStarted(steps, orgAlias);

    const navBarProps = {
      handleClose: this.handleClose,
      isBlockedByGetStarted,
      items: navbarItems,
      settingsSubItems,
      showAccountProgressBar,
      showBilling,
    };

    const navBar = <NavBar {...navBarProps} />;

    return (
      <React.Fragment>
        <div className={['off-canvas', (isMenuOpened) ? 'off-canvas--on' : ''].join(' ')}>
          <SiteWideBannersProvider />
          <div
            role="none"
            className={isMenuOpened ? 'off-canvas-backdrop off-canvas-backdrop--on' : 'off-canvas-backdrop'}
            onClick={this.toggleMenu}
          />

          { showTrialUpgradeBanner && (
            <UpgradeTrialBanner />
          )}

          <Root flexContainer>

            <ReduxToastr newestOnTop {...TOASTR_DEFAULT_PROPS} />
            {!showMessagingHub ? navBar : (
              <ChatTDLive email={user?.email} events={this.getEvents()}>{navBar}</ChatTDLive>
            )}

            <Content isColFlexContainer className="has-off-canvas-menu">
              <ServiceOrderCutoffReminder />
              <TopNav
                isMenuOpened={isMenuOpened}
                onMenuClick={this.toggleMenu}
              />

              { children }
            </Content>
          </Root>
        </div>

        <OffCanvas
          overlayClassName="off-canvas-overlay"
          width="320px"
          height="100%"
          isOpen={isMenuOpened}
          position="left"
          className="off-canvas-menu-container"
          onClose={this.handleClose}
        >
          <MobileNavBar {...navBarProps} />
        </OffCanvas>
      </React.Fragment>
    );
  }
}

LayoutDefault.propTypes = {
  activeOrg: orgSpec.isRequired,
  activeUserCard: userCardSpec.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  dispatch: PropTypes.func.isRequired,
  hasOrgAccess: PropTypes.func.isRequired,
  hasPermission: PropTypes.func.isRequired,
  isManager: PropTypes.bool,
  isOnTrial: PropTypes.bool.isRequired,
  isTrialExpired: PropTypes.bool,
  location: PropTypes.object.isRequired,
  pendingCounts: PropTypes.object.isRequired,
  steps: PropTypes.object.isRequired,
  timeTrackerUnused: PropTypes.object,
  trialSubscription: PropTypes.object,
};

LayoutDefault.defaultProps = {
  children: null,
  isManager: false,
  isTrialExpired: false,
  timeTrackerUnused: {},
  trialSubscription: null,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  activeUserCard: selectActiveUserCard(state),
  hasOrgAccess: getHasOrgAccess(state),
  hasPermission: getActiveUserCardPermissionChecker(state),
  isManager: getHasOrgAccess(state)({ requireManager: true }),
  isOnTrial: selectIsOnTrial(state),
  isTrialExpired: selectIsTrialExpired(state),
  pendingCounts: selectPendingCounts(state),
  steps: selectSetupSteps(state),
  timeTrackerUnused: getViewState(state, timeTrackerComponentName).item,
  trialSubscription: selectTrialSubscription(state),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const LayoutDefaultConnected = connect(mapStateToProps, mapDispatchToProps)(LayoutDefault);

export default withRouter(LayoutDefaultConnected);
