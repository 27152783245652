import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ServiceOrderStatus from 'finance/assets/js/components/ServiceOrderStatus.jsx';
import { financeWorksheetViewUrl } from 'finance/urls';
import { projectMemberAllowedActionsSpec } from 'projects/assets/js/lib/objectSpecs';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { RATE_UNIT } from 'rates/assets/js/constants';
import { BS_SIZE, IMG_SIZE, ICON } from 'core/assets/js/constants';
import Table from 'core/assets/js/components/Table.jsx';
import { modalOpenAC, modalCloseAC, getIsModalOpen, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import { lineBreakToBr, expandColumnComponent } from 'core/assets/js/lib/utils-jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { INVOICE_TYPE } from 'finance/assets/js/constants';
import AttachmentsList from 'core/assets/js/components/AttachmentsList.jsx';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import WorksheetCancelForm from 'projects/assets/js/components/WorksheetCancelForm.jsx';
import WorksheetItemActions from 'finance/assets/js/components/WorksheetItemActions.jsx';
import WorksheetBreakdown from 'finance/assets/js/components/WorksheetBreakdown.jsx';
import withModalForm from 'core/assets/js/components/withModalForm.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import ExchangeRateInfoPopOver from 'core/assets/js/components/ExchangeRateInfoPopOver.jsx';
import { DOCUMENT_QUERY_SELECTOR_ALL } from 'core/assets/js/config/settings';

const WORKSHEET_CANCEL_MODAL_ID = 'ws-cancel-modal';
const WORKSHEET_DETAILS_MODAL_ID = 'worksheet-details-modal';

class WorksheetsTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleCloseWorksheetCancelModal = this.handleCloseWorksheetCancelModal.bind(this);
    this.handleShowWorksheetCancelModal = this.handleShowWorksheetCancelModal.bind(this);
    this.handleDetailsShow = this.handleDetailsShow.bind(this);
    this.handleDetailsClose = this.handleDetailsClose.bind(this);
    this.onTableRowClick = this.onTableRowClick.bind(this);
  }

  componentDidMount() {
    DOCUMENT_QUERY_SELECTOR_ALL('.finance-report tr td *, .finance-report tr td')
      .forEach(trow => trow.addEventListener('click', this.onTableRowClick));
  }

  componentWillUnmount() {
    DOCUMENT_QUERY_SELECTOR_ALL('.finance-report tr td *, .finance-report tr td')
      .forEach(trow => trow.removeEventListener('click', this.onTableRowClick));
  }

  /**
   * prevent default react-bootstrap-table behaviour of expanding when clicking on the whole row
   * making sure that row will only expand when chevron icon is clicked.
   */
  onTableRowClick(e) {
    const { history, match: { params: { orgAlias } } } = this.props;
    const tRow = e && e.target && e.target.closest('tr');

    if (
      e && e.target
      && e.target.tagName !== 'I'
      && !e.target.classList.contains('td-drop-button__toggle')
      && tRow.classList.contains('clickable')
      && e.target.tagName !== 'IMG'
    ) {
      const objectId = [...tRow.classList]
        .find(c => c.startsWith('objectId-'))
        .replace('objectId-', '');

      history.push(
        financeWorksheetViewUrl(orgAlias, objectId),
        history.location.pathname,
        '/projects/',
      );
      e.preventDefault();
      e.stopPropagation();
    }
  }

  handleShowWorksheetCancelModal(worksheet) {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(WORKSHEET_CANCEL_MODAL_ID, { worksheet }));
  }

  handleCloseWorksheetCancelModal() {
    const { dispatch } = this.props;
    return dispatch(modalCloseAC(WORKSHEET_CANCEL_MODAL_ID));
  }

  handleDetailsShow(worksheet) {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(WORKSHEET_DETAILS_MODAL_ID, worksheet));
  }

  handleDetailsClose() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  render() {
    const {
      list, isWorksheetView, embeddedMode, history, modalPayload, location, onItemUpdate,
      isCancelModalOpen, match: { params: { orgAlias } }, location: { pathname }, isModalOpen,
      cancelModalPayload, hasOrgAccess, allowedActions,  ...rest
    } = this.props;

    // Showing different columns on different type of views.
    const worksheetViewColumns = [
      { key: 'projectDescription', label: 'Project' },
      { key: 'managerName', label: 'Manager' },
      {
        key: 'amount',
        label: 'Amount',
        width: '165px',
        columnClassName: 'text-right',
        isMoney: true,
      },
    ];

    const memberColumnSpec = {
      key: 'provider',
      label: 'Name',
      width: '240px',
      dataFormat: (cell, ts) => (
        <div className="d-flex align-items-center">
          <ProfilePic
            className="mr-3"
            url={ts.avatar}
            alt={ts.providerName}
            size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
          />
          {ts.providerName}
        </div>
      ),
    };

    const statusColumnSpec = {
      key: 'statusCode',
      label: 'Status',
      dataFormat: (statusCode, row) => <ServiceOrderStatus serviceOrder={row} />,
    };

    const embeddedViewColumns = [
      memberColumnSpec,
      { key: 'period', label: 'Period' },
      { key: 'amount',
        label: 'Amount',
        columnClassName: 'text-right',
        isMoney: true,
      },
      statusColumnSpec,
    ];

    const ListingViewColumns = [
      memberColumnSpec,
      { key: 'projectDescription', label: 'Project' },
      { key: 'period', label: 'Period' },
      { key: 'amount',
        label: 'Amount',
        columnClassName: 'text-right',
        isMoney: true,
      },
      statusColumnSpec,
    ];

    let tableColumns;
    // Show only specific columns based on where the table is being shown.
    if (isWorksheetView) {
      tableColumns = worksheetViewColumns;
    } else if (embeddedMode) {
      tableColumns = embeddedViewColumns;
    } else {
      tableColumns = ListingViewColumns;
    }

    // If is listing, add actions column.
    if (!isWorksheetView) {
      tableColumns.push({
        key: 'actions',
        width: '80px',
        label: '',
        dataAlign: 'right',
        columnClassName: 'actions-cell',
        dataFormat: (cell, row) => {
          return (
            <WorksheetItemActions
              history={history}
              orgAlias={orgAlias}
              worksheet={row}
              pathname={pathname}
              onCancelClick={() => this.handleShowWorksheetCancelModal(row)}
            />
          );
        },
      });
    }

    let tableOptions = {
      onRowClick: this.onTableRowClick,
      expandableRow: () => true,
      expandComponent: (worksheet) => {
        if (worksheet.attachments) {
          return <AttachmentsList attachments={worksheet.attachments} label="Worksheet Attachments" />;
        }
        // worksheet doesn't necessarily have attachments, we show summery in ws with no attachments
        return lineBreakToBr(worksheet.summary);
      },
      expandColumnOptions: {
        expandColumnVisible: true,
        expandColumnComponent,
        columnWidth: '30px',
      },
    };

    if (isWorksheetView) {
      tableOptions = {
        onRowClick: this.handleDetailsShow,
      };
    }

    // Format data and add custom columns
    const items = list.map(ws => ({
      ...ws,
      amount: (
        <>
          <NumberTpl currency={ws.worksheetCurrency} value={ws.worksheetAmount} />
          {ws.organizationCurrency !== ws.worksheetCurrency && (
            <ExchangeRateInfoPopOver
              className="ml-2"
              exchangeRate={ws.exchangeRate}
              exchangeRateDate={ws.exchangeRateUpdatedAt}
              exchangeRateService={ws.worksheetToOrganizationFxRateSource}
              sourceCurrency={ws.worksheetCurrency}
              targetAmount={ws.organizationAmount}
              targetCurrency={ws.organizationCurrency}
            />
          )}
        </>
      ),
      projectDescription: (
        <div className={`${isWorksheetView ? 'modal-hover-effect' : ''} d-flex`}>
          {isWorksheetView && <i className={`${ICON.ARROW_UP_RIGHT_FROM_SQUARE}`} />}
          <div className={isWorksheetView ? 'modal-hover-effect__text' : ''}>
            {`${ws.projectTitle} (${ws.projectReference}${ws.externalProjectId ? `/${ws.externalProjectId}` : ''})`}
          </div>
        </div>
      ),
      total: ws.organizationAmount,
      invoiceNumbers: (ws.invoices || [])
        // get all provider invoices
        .filter(i => i && [INVOICE_TYPE.INBOUND, INVOICE_TYPE.DIRECT].includes(i.type))
        .map(i => i.number)
        .join(', '),
    }));

    const CancelForm = withModalForm({
      heading: 'Cancel worksheet',
      withFooter: false,
    })(WorksheetCancelForm);

    return (
      <React.Fragment>
        <Table
          cols={tableColumns}
          items={items}
          {...rest}
          {...tableOptions}
          isLarge={!embeddedMode}
          trClassName={(row) => { return `objectId-${row && row.id}`; }}
        />

        { modalPayload && (
          <ModalSimple
            data-testid="worksheets-table-breakdown"
            open={isModalOpen}
            heading={`${modalPayload.providerName} for ${modalPayload.period}`}
            size={BS_SIZE.LARGE}
            body={(
              <WorksheetBreakdown
                worksheet={modalPayload}
                allowedActions={allowedActions}
              />
            )}
            onClose={this.handleDetailsClose}
          />
        )}

        { cancelModalPayload && (
          <CancelForm
            isModalOpen={isCancelModalOpen}
            onClose={this.handleCloseWorksheetCancelModal}
            initialValues={{
              quantity: cancelModalPayload.worksheet.quantity,
              summary: cancelModalPayload.worksheet.summary,
              attachments: cancelModalPayload.worksheet.attachments,
            }}
            worksheet={cancelModalPayload.worksheet}
            orgAlias={orgAlias}
            onCancelSuccess={(canceled, projectAllowedAction) => {
              this.handleCloseWorksheetCancelModal();
              onItemUpdate(canceled, projectAllowedAction);
            }}
            question="Are you sure you want to cancel this Worksheet?"
          />
        )}
      </React.Fragment>
    );
  }
}

WorksheetsTable.propTypes = {
  allowedActions: projectMemberAllowedActionsSpec,
  embeddedMode: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
  isCancelModalOpen: PropTypes.bool,
  isWorksheetView: PropTypes.bool,
  hasOrgAccess: PropTypes.func.isRequired,
  onItemUpdate: PropTypes.func,
  location: PropTypes.object.isRequired,
  history: routerHistorySpec.isRequired,
  list: PropTypes.array,
  match: routerMatchSpec.isRequired,
  modalPayload: PropTypes.object,
  cancelModalPayload: PropTypes.object,
  orgAlias: PropTypes.string,
  pathname: PropTypes.string,
  rateUnit: PropTypes.oneOf(Object.values(RATE_UNIT)),
};

WorksheetsTable.defaultProps = {
  allowedActions: {},
  pathname: null,
  onItemUpdate: () => {},
  embeddedMode: false,
  isWorksheetView: false,
  isModalOpen: false,
  isCancelModalOpen: false,
  list: [],
  modalPayload: null,
  cancelModalPayload: null,
  orgAlias: '',
  rateUnit: RATE_UNIT.PER_HOUR,
};

const mapStateToProps = state => ({
  hasOrgAccess: getHasOrgAccess(state),
  isCancelModalOpen: getIsModalOpen(state, WORKSHEET_CANCEL_MODAL_ID),
  cancelModalPayload: getModalPayload(state, WORKSHEET_CANCEL_MODAL_ID),
  isModalOpen: getIsModalOpen(state, WORKSHEET_DETAILS_MODAL_ID),
  modalPayload: getModalPayload(state, WORKSHEET_DETAILS_MODAL_ID),
});
const mapDispatchToProps = dispatch => ({
  dispatch,
});
const WorksheetsTableConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorksheetsTable);

export default withRouter(WorksheetsTableConnected);
