import { omit, pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link, useHistory, withRouter } from 'react-router-dom';
import queryString from 'query-string';

import MoneyInputField from 'core/assets/js/components/FinalFormFields/MoneyInputField.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import SelectableListFilterField from 'core/assets/js/components/FinalFormFilterFields/SelectableListFilterField.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import SearchFinalForm from 'core/assets/js/components/SearchFinalForm.jsx';
import SplitButton from 'core/assets/js/components/SplitButton.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';
import { BS_STYLE, CURRENCY, MODAL_SIZES, ORDERING_DIRECTION } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import {
  getIsModalOpen, getModalPayload, modalOpenAC, modalCloseAC,
} from 'core/assets/js/ducks/modalLauncher';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import axios from 'core/assets/js/lib/tdAxios';
import { getDatetime, getYearsSince } from 'core/assets/js/lib/utils';
import {
  US_1099_CONTRACTORS_COMPONENT_NAME,
  US_TAX_1099_CANNOT_FILE_REASON_DESCRIPTION,
  US_TAX_1099_FILING_STATUS,
  US_TAX_1099_FILING_STATUS_CLASS,
  US_TAX_1099_FILING_STATUS_LABEL,
  US_TAX_1099_FILING_STATUS_VALUES,
  US_TAX_FILING_TABS,
} from 'finance/assets/js/constants';
import { getSelected1099Year } from 'finance/assets/js/ducks/hooks';
import { download1099FilingPDF } from 'finance/assets/js/lib/utils';
import FinanceTableSkeleton from 'finance/assets/js/skeletons/FinanceTableSkeleton.jsx';
import {
  finance1099FilingsUrl,
  financeFileIndividual1099ApiUrl,
  financeGet1099ContractorsApiUrl,
  financeUpdate1099OffPlatformPaymentsApiUrl,
} from 'finance/urls';
import {
  getActiveUserCardPermissionChecker, selectActiveOrg,
} from 'organizations/assets/js/reducers/organizations';
import { orgUserProfileUrl } from 'people/urls';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { PERMISSIONS } from 'roles/assets/js/constants';

const orderingOptions = [{ text: 'Last name', value: 'lastName' }];
const defaultOrdering = { direction: ORDERING_DIRECTION.ASC, sortBy: 'lastName' };

const ERROR_MODAL_ID = '1099-contractors-error-modal';
const FILE_MODAL_ID = '1099-contractor-file-modal';
const OFF_PLATFORM_PAYMENTS_MODAL_ID = '1099-edit-off-platform-payments-modal';

const US1099Contractors = ({
  filtersOpen, location: { search }, match: { params }, onFiltersToggle, reload,
}) => {
  const { hasLoaded, isLoading, items, pagination, search: { isActive } } = fetchDataHook({
    componentName: US1099Contractors.GetComponentName(params),
    duck: 'list',
    url: financeGet1099ContractorsApiUrl(params.orgAlias),
  });
  const activeOrg = useSelector(selectActiveOrg);
  const hasPermission = useSelector(getActiveUserCardPermissionChecker);
  const dispatch = useDispatch();
  const errorModalIsOpen = useSelector(state => getIsModalOpen(state, ERROR_MODAL_ID));
  const errorModalPayload = useSelector(state => getModalPayload(state, ERROR_MODAL_ID));
  const fileModalIsOpen = useSelector(state => getIsModalOpen(state, FILE_MODAL_ID));
  const fileModalPayload = useSelector(state => getModalPayload(state, FILE_MODAL_ID));
  const editOffPlatformPaymentsModalIsOpen = useSelector(
    state => getIsModalOpen(state, OFF_PLATFORM_PAYMENTS_MODAL_ID),
  );
  const editOffPlatformPaymentsModalPayload = useSelector(
    state => getModalPayload(state, OFF_PLATFORM_PAYMENTS_MODAL_ID),
  );
  const history = useHistory();
  const formRef = useRef(null);

  const parsedQueryString = queryString.parse(search);

  const year = getSelected1099Year();

  const url = finance1099FilingsUrl(activeOrg.alias, US_TAX_FILING_TABS['1099_FILINGS']);

  const allowedYears = activeOrg.createdAtYear ? getYearsSince(activeOrg.createdAtYear) : [];
  const twoYearsAgo = parseInt(getDatetime().subtract(2, 'years').format('YYYY'), 10);
  if (activeOrg.createdAtYear && twoYearsAgo < activeOrg.createdAtYear) {
    // Always include the preceding two years, as those are the years Tax Bandits supports filing
    // 1099s for
    allowedYears.unshift(
      ...new Array(activeOrg.createdAtYear - twoYearsAgo)
        .fill('')
        .map((_, index) => twoYearsAgo + index),
    );
  }

  useEffect(() => {
    if (editOffPlatformPaymentsModalIsOpen && formRef.current) {
      // unset if the modal is opened, so the Form's render will set it to the correct
      // form instance
      formRef.current = null;
    }
  }, [editOffPlatformPaymentsModalIsOpen]);

  if (year && !allowedYears.includes(parseInt(year, 10))) {
    return <RedirectRoute status={302} to={url} />;
  }

  if (
    !activeOrg['1099_filings_enabled']
    || (
      !hasPermission(PERMISSIONS.CAN_FILE_1099_REPORTS)
      && !hasPermission(PERMISSIONS.CAN_VIEW_1099_CONTRACTORS)
    )
  ) {
    return <RedirectRoute status={302} to={finance1099FilingsUrl(activeOrg.alias)} />;
  }

  if (!hasLoaded || isLoading) {
    return <FinanceTableSkeleton />;
  }

  const columns = [
    {
      dataFormat: (contractorName, { userId, userType }) => (
        <Link to={orgUserProfileUrl(params.orgAlias, userType, userId)}>
          {contractorName}
        </Link>
      ),
      key: 'contractorName',
      label: 'Name',
    },
    {
      dataFormat: isIncorporated => (isIncorporated ? 'Company' : 'Individual'),
      key: 'isIncorporated',
      label: 'Type',
    },
    { key: 'taxIdentificationNumber', label: 'Tax id' },
    {
      dataFormat: amount => <NumberTpl currency={activeOrg.currency} value={amount} />,
      key: 'totalAmount',
      label: 'Amount paid',
    },
    {
      dataFormat: status => (
        <StatusTag
          label={US_TAX_1099_FILING_STATUS_LABEL[status]}
          statusClass={US_TAX_1099_FILING_STATUS_CLASS[status]}
        />
      ),
      key: 'status',
      label: 'Status',
    },
    {
      dataAlign: 'right',
      dataFormat: (_, user) => {
        const {
          allowedActions: { canSetOffPlatformPayment },
          error,
          status,
          tax1099FilingRequestId,
        } = user;
        const actions = [{
          label: 'File 1099', onClick: () => dispatch(modalOpenAC(FILE_MODAL_ID, { user })),
        }];
        if (canSetOffPlatformPayment) {
          actions.push({
            className: 'text-nowrap',
            label: 'Edit off-platform payments',
            onClick: () => dispatch(modalOpenAC(OFF_PLATFORM_PAYMENTS_MODAL_ID, { user })),
          });
        }
        if (status === US_TAX_1099_FILING_STATUS.ERROR && error) {
          actions.push({
            label: 'View error',
            onClick: () => dispatch(modalOpenAC(ERROR_MODAL_ID, { error })),
          });
        } else if (status === US_TAX_1099_FILING_STATUS.FILED) {
          actions.push({
            label: 'Download',
            onClick: () => download1099FilingPDF(activeOrg.alias, tax1099FilingRequestId),
          });
        }
        return <SplitButton actions={actions} primaryButtonVariant={BS_STYLE.DEFAULT} />;
      },
      key: 'userId',
      label: '',
    },
  ];

  return (
    <>
      {isActive && (
        <SearchFinalForm
          className="px-0 mt-5"
          extraSearchElement={(
            <>
              <SelectField
                defaultOptionText={null}
                name="year"
                optionsMapping={allowedYears.map(value => ({ text: value, value }))}
                wrapperClasses="mb-0 mr-3 us-1099-contractors-year-selector"
              />
              <FormSpy
                onChange={({ values }) => {
                  if (values.year && parseInt(values.year, 10) === year) {
                    return;
                  }
                  history.push([
                    url,
                    queryString.stringify({
                      ...parsedQueryString, year: values.year || new Date().getFullYear(),
                    }),
                  ].join('?'));
                }}
              />
            </>
          )}
          initialValues={{
            ...pick(parsedQueryString, 'kw', 'status'),
            ordering: parsedQueryString.ordering || defaultOrdering,
            year,
          }}
          positionExtraSearchElementBefore
          searchSpec={{
            defaultOrdering,
            orderingOptions,
            searchTerm: {
              component: TextInputField,
              paramName: 'kw',
              placeholder: 'Search contractors',
            },
            filters: [
              // Note: Ordering filter is only shown on mobile devices.
              {
                fieldComponent: SelectableListFilterField,
                isOrdering: true,
                label: 'Sort By',
                options: orderingOptions,
                multiple: false,
                paramName: 'ordering',
              },
              {
                fieldComponent: SelectableListFilterField,
                label: 'Status',
                multiple: true,
                options: US_TAX_1099_FILING_STATUS_VALUES.map(value => ({
                  text: US_TAX_1099_FILING_STATUS_LABEL[value], value,
                })),
                paramName: 'status',
              },
            ],
          }}
          onFiltersToggle={onFiltersToggle}
          filtersOpen={filtersOpen}
        />
      )}
      <Table cols={columns} containerClass="us-1099-contractors-table" items={items} />
      <TDPagination {...pagination} />
      <ModalConfirm
        onClose={() => dispatch(modalCloseAC())}
        open={errorModalIsOpen}
        heading="1099 filing error"
        body={() => {
          if (!errorModalPayload?.error) {
            return null;
          }
          if (!errorModalPayload?.error._error) {
            return (
              <pre className="us-1099-filing-error p-5">
                {JSON.stringify(errorModalPayload.error, null, 2)}
              </pre>
            );
          }
          const propertyErrors = Object.keys(omit(errorModalPayload.error, '_error'));
          return (
            <>
              <b>{errorModalPayload.error._error}</b>
              {propertyErrors.length > 0 && (
                <ul className="mt-5">
                  {propertyErrors.map(property => {
                    const error = errorModalPayload.error[property];
                    if (!error) {
                      return null;
                    }
                    if (typeof error === 'string') {
                      return (
                        <li key={property}>
                          <b className="mr-2">{`${property}:`}</b>
                          <span>{error}</span>
                        </li>
                      );
                    }
                    const nestedPropertyErrors = Object.keys(error);
                    return nestedPropertyErrors.map(nestedProperty => {
                      const key = `${property}.${nestedProperty}:`;
                      return (
                        <li key={key}>
                          <b className="mr-2">{key}</b>
                          <span>{errorModalPayload.error[property][nestedProperty]}</span>
                        </li>
                      );
                    });
                  })}
                </ul>
              )}
            </>
          );
        }}
        showConfirmButton={false}
        size={MODAL_SIZES.LARGE}
      />
      <ModalConfirm
        body={fileModalPayload?.user && (
          <>
            {fileModalPayload?.user?.allowedActions.canFile && (
              <>
                <p>
                  {`This will file a ${year} 1099 form for `}
                  {`${fileModalPayload.user.contractorName} in the amount of `}
                  {`$${fileModalPayload.user.totalAmount}.`}
                </p>
                <p>
                  This will run in the background and take some time, we will notify you if there
                  are any errors.
                </p>
              </>
            )}
            {!fileModalPayload?.user?.allowedActions.canFile && (
              <>
                <p>You cannot file a 1099-NEC form for this user:</p>
                <ul className="bullet-list">
                  {fileModalPayload?.user?.allowedActions.cannotFileReasons.map(reason => (
                    <li key={reason}>{US_TAX_1099_CANNOT_FILE_REASON_DESCRIPTION[reason]}</li>
                  ))}
                </ul>
              </>
            )}
          </>
        )}
        confirmLabel="File 1099"
        heading="File 1099"
        onClose={() => dispatch(modalCloseAC())}
        onConfirm={async () => {
          try {
            await axios.post(
              financeFileIndividual1099ApiUrl(activeOrg.alias),
              { ...pick(fileModalPayload.user, 'userCardTaxFormReviewId'), year },
            );
            toastr.success(
              'Well Done!',
              [
                `We have started filing ${fileModalPayload.user.contractorName}'s 1099 for ${year}`,
                '. If there are any errors, we will notify you',
              ].join(''),
            );
            reload();
          } catch (e) {
            toastr.error('Oh Snap!', e.response?.data?._error || e.message);
          }
        }}
        open={fileModalIsOpen}
        showConfirmButton={fileModalPayload?.user?.allowedActions.canFile}
      />
      <ModalConfirm
        body={editOffPlatformPaymentsModalPayload?.user && (
          <Form
            initialValues={{
              amount: editOffPlatformPaymentsModalPayload.user.offPlatformAmount || '0.00',
            }}
            onSubmit={() => null}
            render={({ form, handleSubmit, submitting }) => {
              if (!formRef.current) {
                // this is a bit hacky, but as of react-final-form v6, using `<Form ref` is broken
                // https://github.com/final-form/react-final-form/issues/483
                formRef.current = form;
              }
              return (
                <form onSubmit={handleSubmit}>
                  <MoneyInputField
                    currency={CURRENCY.USD}
                    disabled={submitting}
                    label={[
                      'Enter the amount paid to ',
                      editOffPlatformPaymentsModalPayload?.user?.contractorName,
                    ].join('')}
                    name="amount"
                    sublabel="If the contractor has received money outside of the TalentDesk platform"
                  />
                </form>
              );
            }}
          />
        )}
        confirmLabel="Save"
        heading={[
          'Edit off-platform payments for ',
          editOffPlatformPaymentsModalPayload?.user?.contractorName || 'contractor',
        ].join('')}
        onClose={() => dispatch(modalCloseAC())}
        onConfirm={async () => {
          if (!editOffPlatformPaymentsModalPayload?.user || !formRef.current) {
            return;
          }
          const { amount } = formRef.current.getState().values;
          try {
            const { data: { amount: updatedAmount } } = await axios.put(
              financeUpdate1099OffPlatformPaymentsApiUrl(activeOrg.alias),
              {
                ...pick(editOffPlatformPaymentsModalPayload.user, 'userCardTaxFormReviewId'),
                amount,
                year,
              },
            );
            toastr.success(
              'Well Done!',
              [
                `You have updated ${editOffPlatformPaymentsModalPayload.user.contractorName}'s `,
                `1099 off-platform payments amount to $${updatedAmount}, for ${year}.`,
              ].join(''),
            );
            reload();
          } catch (e) {
            toastr.error('Oh Snap!', e.response?.data?._error || e.message);
            // Throw the error to prevent the modal closing
            throw e;
          }
        }}
        open={
          editOffPlatformPaymentsModalIsOpen
          && editOffPlatformPaymentsModalPayload?.user?.taxIdentificationNumber
        }
      />
      <ModalSimple
        body={(
          <p>
            You cannot set off-platform payments for this user,
            until they have an approved W8 or W9 form.
          </p>
        )}
        heading={[
          'Edit off-platform payments for ',
          editOffPlatformPaymentsModalPayload?.user?.contractorName || 'contractor',
        ].join('')}
        onClose={() => dispatch(modalCloseAC())}
        open={
          editOffPlatformPaymentsModalIsOpen
          && !editOffPlatformPaymentsModalPayload?.user?.taxIdentificationNumber
        }
        size={MODAL_SIZES.DEFAULT}
      />
    </>
  );
};

US1099Contractors.GetComponentName = () => US_1099_CONTRACTORS_COMPONENT_NAME;

US1099Contractors.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
  reload: PropTypes.func.isRequired,
};

export default withFilters(withRouter(US1099Contractors));
