import queryString from 'query-string';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import TabBar from 'core/assets/js/components/TabBar.jsx';
import { PENDING_COUNT_TYPE, selectPendingCounts } from 'core/assets/js/ducks/pendingCount';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import OpportunityInvitations from 'projects/assets/js/components/invitationsTabs/OpportunityInvitations.jsx';
import TaskOrProjectInvitations from 'projects/assets/js/components/invitationsTabs/TaskOrProjectInvitations.jsx';
import { INVITATIONS_LIST_TABS } from 'projects/assets/js/constants';
import { projectInvitationsUrl } from 'projects/urls';

const ProjectInvitationListView = () => {
  const params = useParams();
  const { orgAlias } = params;
  const location = useLocation();
  const parsedQueryString = queryString.parse(location.search);
  const tab = parsedQueryString.tab || INVITATIONS_LIST_TABS.TASKS;

  const pendingCounts = useSelector(selectPendingCounts);

  return (
    <>
      <ContentHeader breadcrumbs={[{ title: 'Invitations', url: null }]}>
        <div className="horizontal-scroll">
          <TabBar
            activeKey={tab}
            tabSpec={[
              {
                href: projectInvitationsUrl(orgAlias, { tab: INVITATIONS_LIST_TABS.TASKS }),
                key: INVITATIONS_LIST_TABS.TASKS,
                label: 'Tasks',
                outstandingCount: pendingCounts[PENDING_COUNT_TYPE.TASK_INVITATIONS].count,
              },
              {
                href: projectInvitationsUrl(orgAlias, { tab: INVITATIONS_LIST_TABS.PROJECTS }),
                key: INVITATIONS_LIST_TABS.PROJECTS,
                label: 'Projects',
                outstandingCount: pendingCounts[PENDING_COUNT_TYPE.PROJECT_INVITATIONS].count,
              },
              /*
              TODO enable when the opportunity invitees feature is ready
              {
                href: projectInvitationsUrl(orgAlias, { tab: INVITATIONS_LIST_TABS.OPPORTUNITIES }),
                key: INVITATIONS_LIST_TABS.OPPORTUNITIES,
                label: 'Opportunities',
                outstandingCount: pendingCounts[PENDING_COUNT_TYPE.OPPORTUNITY_INVITATIONS].count,
              },
              */
            ]}
          />
        </div>
      </ContentHeader>
      <div className="page page--projects">
        <div className="container">
          {[INVITATIONS_LIST_TABS.TASKS, INVITATIONS_LIST_TABS.PROJECTS].includes(tab) && (
            <TaskOrProjectInvitations />
          )}
          {tab === INVITATIONS_LIST_TABS.OPPORTUNITIES && <OpportunityInvitations />}
        </div>
      </div>
    </>
  );
};

ProjectInvitationListView.GetComponentName = () => 'ProjectInvitationListView';

export default ProjectInvitationListView;
