/* globals FormData */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectProfile } from 'accounts/assets/js/reducers/auth';

import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import RateField from 'rates/assets/js/components/finalFormFields/RateField.jsx';
import { formatDate, parseAxiosErrorForFinalForm } from 'core/assets/js/lib/utils';
import { toastr } from 'react-redux-toastr';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import FileUploaderDirectField from 'core/assets/js/components/FinalFormFields/FileUploaderDirectField.jsx';
import LoadingComponent from 'core/assets/js/components/LoadingComponent.jsx';
import ProjectBriefDescription from 'projects/assets/js/components/ProjectBriefDescription.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { createProjectApplicationDS, updateProjectApplicationDS, resendProjectApplicationDS } from 'projects/assets/js/data-services/form';
import { BS_STYLE, MIME_TYPES, MAX_UPLOAD_FILES } from 'core/assets/js/constants';
import { projectOpportunitiesUrl } from 'projects/urls';
import { RATE_UNIT_FORMAT } from 'rates/assets/js/constants';

const FormRenderer = ({
  handleSubmit, history, initialValues, isReApply, project, submitting, userCard,
}) => {
  const {
    currencySymbol,
    rate,
    rateUnit,
  } = userCard;

  const {
    applicants,
    applicationsCount,
    created_at,
    deadline,
    max_applicants,
    owner_name,
    rateGuideUnit,
  } = project;

  const hasCompleteRate = (currencySymbol && rate && rateUnit);
  // Rate display
  const rateDisplayOpts = {
    currency: currencySymbol,
  };
  if (hasCompleteRate) {
    rateDisplayOpts.showRateUnitDiscreetly = true;
  }

  let submitLabel;

  if (isReApply) {
    submitLabel = 'Reapply';
  } else if (initialValues.id) {
    submitLabel = 'Save';
  } else {
    submitLabel = 'Send';
  }

  const rateUnitFormat = rateGuideUnit && RATE_UNIT_FORMAT[rateGuideUnit];

  return (
    <React.Fragment>
      {submitting && <LoadingComponent />}

      <form onSubmit={handleSubmit} className="project-application-form">
        <div className="row">
          <div className="col-12 col-md-5 mb-4 mb-md-0">
            <Card>
              <Card.Header>
                {project.title}
                <aside className="discreet">
                  <div className="mb-2">
                    {`Posted on ${formatDate(created_at)} by ${owner_name}`}
                  </div>

                  <div className="d-flex align-items-center">
                    { applicants && applicants.length > 0 && (
                      <div className="project-opportunities-card__applicants mr-3">
                        { applicants.slice(0, 5).map((
                          { user: { id: applicantId, profile: { avatar, name } } },
                        ) => (
                          <ProfilePic
                            key={applicantId}
                            url={avatar}
                            alt={name}
                            size={[20, 20]}
                          />
                        ))}
                      </div>
                    )}

                    {max_applicants > 0 && (
                      <span className="mr-4">
                        {`${applicationsCount || 0}/${max_applicants} applicants`}
                      </span>
                    )}

                    {deadline && (
                      <span>
                        {`Deadline ${formatDate(deadline)}`}
                      </span>
                    )}
                  </div>
                </aside>
              </Card.Header>
              <Card.Body>
                <ProjectBriefDescription
                  project={project}
                  truncateDescription
                  words={100}
                />
              </Card.Body>
            </Card>
          </div>

          <div className="col-12 col-md-7">
            <Card>
              <Card.Header>
                Apply for this opportunity
              </Card.Header>
              <Card.Body>
                {rateUnitFormat && (
                  <RateField
                    currency={project.currency}
                    disabled={!!project.rateGuideFixed}
                    label={`Your rate (${rateUnitFormat.suffix})`}
                    max={project.rateGuideMaximum ? parseFloat(project.rateGuideMaximum) : null}
                    min={project.rateGuideMinimum ? parseFloat(project.rateGuideMinimum) : null}
                    name="rateAmount"
                    unit={rateGuideUnit}
                  />
                )}

                <TextAreaField
                  name="message"
                  label="Application message"
                  mdEditorEnabled
                  required
                  height={190}
                />

                <FileUploaderDirectField
                  acceptFiles={[...MIME_TYPES.DOCUMENTS, ...MIME_TYPES.IMAGES]}
                  allowMultiple
                  label="Attachments"
                  maxFiles={MAX_UPLOAD_FILES}
                  name="attachments"
                />
              </Card.Body>
            </Card>

            <div className="col-12 mt-4 text-right px-0 d-flex justify-content-end">
              <TDButton
                variant={BS_STYLE.DEFAULT}
                onClick={() => {
                  history.push(projectOpportunitiesUrl(userCard.organization.id));
                }}
                label="Cancel"
              />

              <TDButton
                variant={BS_STYLE.PRIMARY}
                label={submitLabel}
                disabled={submitting}
                type="submit"
              />
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};

FormRenderer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  history: routerHistorySpec.isRequired,
  orgAlias: PropTypes.string,
  isReApply: PropTypes.bool,
  project: projectSpec.isRequired,
  userCard: userCardSpec.isRequired,
  initialValues: PropTypes.object,
};

FormRenderer.defaultProps = {
  isReApply: false,
  orgAlias: '',
  initialValues: {},
};


class ProjectApplicationForm extends React.Component {
  static GetComponentName() {
    return 'ProjectApplicationForm';
  }

  constructor(props) {
    super(props);
    this.props = props;

    this.submit = this.submit.bind(this);
  }

  async submit(values) {
    const { orgAlias, project, onSubmitSuccess, initialValues, isReApply } = this.props;
    const applicationId = initialValues.id;

    const formData = new FormData();
    formData.append('message', values.message || '');
    if (project.rateGuideUnit) {
      formData.append('rateAmount', values.rateAmount || '');
    }
    if (Array.isArray(values.attachments) && values.attachments.length > 0) {
      values.attachments.forEach(attachment => {
        formData.append('attachments[]', attachment);
      });
    }

    let dataService;
    let messageText;

    if (applicationId && !isReApply) {
      dataService = () => updateProjectApplicationDS(orgAlias, project.id, applicationId, formData);
      messageText = 'Application updated successfully';
    } else if (applicationId && isReApply) {
      dataService = () => resendProjectApplicationDS(orgAlias, project.id, applicationId, formData);
      messageText = 'Application re-sent successfully';
    } else {
      dataService = () => createProjectApplicationDS(orgAlias, project.id, formData);
      messageText = 'Application created successfully';
    }

    try {
      const response = await dataService();
      toastr.success('Well Done!', messageText);

      if (onSubmitSuccess) {
        onSubmitSuccess(response.data);
      }
      return null;
    } catch (e) {
      return parseAxiosErrorForFinalForm(e);
    }
  }

  render() {
    const { initialValues, ...passThroughProps } = this.props;

    return (
      <Form
        onSubmit={this.submit}
        initialValues={initialValues}
        render={FormRenderer}
        {...passThroughProps}
      />
    );
  }
}

ProjectApplicationForm.propTypes = {
  initialValues: PropTypes.object,
  history: routerHistorySpec.isRequired,
  isReApply: PropTypes.bool,
  submitLabel: PropTypes.string,
  onSubmitSuccess: PropTypes.func,
  orgAlias: PropTypes.string,
  project: projectSpec.isRequired,
  userCard: userCardSpec.isRequired,
  profile: PropTypes.object.isRequired,
};

ProjectApplicationForm.defaultProps = {
  initialValues: {},
  onSubmitSuccess: () => {},
  submitLabel: '',
  orgAlias: '',
  isReApply: false,
};

const mapStateToProps = state => ({
  userCard: selectActiveUserCard(state),
  profile: selectProfile(state),
});

const ProjectApplicationFormConnected = connect(mapStateToProps)(ProjectApplicationForm);
export default withRouter(ProjectApplicationFormConnected);
