import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Card } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { toastr } from 'react-redux-toastr';

import { CALENDAR_TYPE } from 'analytics/assets/js/constants';
import { dataReportSpec } from 'analytics/assets/js/lib/objectSpecs';
import { fetchReportInfoDS } from 'analytics/assets/js/data-services/analytics';
import { reportsDownloadReportApiUrl, reportsUrl } from 'analytics/urls';
import { BS_STYLE, BS_SIZE, API_DATE_FORMAT } from 'core/assets/js/constants';
import { numberToLetters } from 'core/assets/js/lib/utils';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import DatePickerField from 'core/assets/js/components/FinalFormFields/DatePickerField.jsx';
import DateRangePickerField from 'core/assets/js/components/FinalFormFields/DateRangePickerField.jsx';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { getViewState } from 'core/assets/js/ducks/view';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import {
  getIsModalOpen,
  modalCloseAC,
  modalOpenAC,
} from 'core/assets/js/ducks/modalLauncher';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';


const CSV_INFO_MODAL = 'csv-info-modal';

class ReportView extends React.PureComponent {
  static FetchData({ dispatch, params, url = '', authedAxios, componentName }) {
    return dispatch(fetchReportInfoDS({
      orgAlias: params.orgAlias, id: params.id, url, authedAxios, componentName }));
  }

  static GetComponentName() {
    return 'ReportView';
  }

  constructor(props) {
    super(props);
    this.props = props;

    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleModalClose() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC(CSV_INFO_MODAL));
  }

  handleModalOpen() {
    const { dispatch } = this.props;

    dispatch(modalOpenAC(CSV_INFO_MODAL));
  }

  handleSubmit(values) { // eslint-disable-line consistent-return
    const { match } = this.props;
    const { params: { orgAlias } } = match;

    let startDate;
    let endDate;
    if (values.date) {
      startDate = moment(values.date);
    } else {
      startDate = moment(values.period.startDate);
      endDate = moment(values.period.endDate);
    }

    if (startDate && endDate && endDate.diff(startDate, 'months', true) > 12) {
      const errorMsg = `
        The selected report period is "${startDate.format(API_DATE_FORMAT)}" to
        "${endDate.format(API_DATE_FORMAT)}". You cannot select a date range of
        more than 12 months for your report.
      `;
      return toastr.error('Oh Snap!', errorMsg);
    }

    const startDateFormatted = startDate.format(API_DATE_FORMAT);
    const endDateFormatted = endDate ? endDate.format(API_DATE_FORMAT) : '';
    // Do not use a `return` statement to satisfy the `consistent-return` linting rule,
    // or final form will throw a `Maximum call stack size exceeded` error.
    WINDOW_OPEN(
      reportsDownloadReportApiUrl(orgAlias, values.id, startDateFormatted, endDateFormatted),
    );
  }

  render() {
    const { history, isModalOpen, match: { params: { orgAlias } }, report } = this.props;
    const breadcrumbs = [
      {
        title: 'Reports',
        url: reportsUrl(orgAlias),
      },
      {
        title: 'Download report',
        url: null,
      },
    ];

    const initialValues = {
      id: report.id,
    };

    if (report.calendarType !== CALENDAR_TYPE.DATE_RANGE_PICKER) {
      initialValues.date = moment().subtract(1, 'months').format('YYYY-MM');
    } else {
      initialValues.period = {
        periodStart: moment().startOf('month').format(API_DATE_FORMAT),
        periodEnd: moment().format(API_DATE_FORMAT),
      };
    }

    return (
      <>
        <ContentHeader breadcrumbs={breadcrumbs} />
        <div className="page page--reports">
          <div className="container">
            <TDApiConnected
              duck="view"
              component={this.constructor}
              skeletonComponent={SettingsPageSkeleton}
            >
              <Form
                name="report-form"
                onSubmit={this.handleSubmit}
                initialValues={initialValues}
                render={({
                  handleSubmit, submitting, values: { date, period }, invalid,
                }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      {report.columns && (
                        <ModalSimple
                          heading={report.title}
                          open={isModalOpen}
                          size={BS_SIZE.LARGE}
                          body={(
                            <>
                              <p>{report.description}</p>
                              <p>The report will be in CSV format with the following headers.</p>
                              <div className="overflow-auto">
                                <table width={report.columns.length * 70} className="spreadsheet-like">
                                  <thead>
                                    <tr>
                                      <th key="th-blank">&nbsp;</th>
                                      {report.columns.map((val, idx) => (
                                        <th key={`th-${idx}`}>
                                          {numberToLetters(idx)}
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td key="td1-counter">1</td>
                                      {report.columns.map((val, idx) => (
                                        <td key={`td1-${idx}`}>
                                          {val}
                                        </td>
                                      ))}
                                    </tr>
                                    <tr>
                                      <td key="td2-counter">2</td>
                                      {report.columns.map((val, idx) => (
                                        <td key={`td2-${idx}`}>
                                          ...
                                        </td>
                                      ))}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </>
                          )}
                          onClose={this.handleModalClose}
                          noFooter
                        />
                      )}
                      <Card className="project-task__details">
                        <Card.Header data-testid="report-header">{report.title}</Card.Header>
                        <Card.Body>
                          <p className="mb-2" data-testid="report-description">
                            {report.description}
                          </p>
                          <p className="mb-5">
                            <span className="imitate-link" onClick={this.handleModalOpen}>
                              View report details
                            </span>
                          </p>
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <input type="hidden" name="id" />
                              {report.calendarType === CALENDAR_TYPE.DATE_PICKER && (
                                <DatePickerField
                                  name="date"
                                  label="Report date"
                                  sublabel="Select the target year and month for the report"
                                  monthSelection
                                  required
                                  validate={(val) => {
                                    const selectedYear = moment(val).year();
                                    const selectedMonth = moment(val).month();
                                    const now = moment();

                                    if (
                                      selectedYear > now.year() || (
                                        selectedYear === now.year() && selectedMonth > now.month()
                                      )) {
                                      return 'Select a past date';
                                    }

                                    return null;
                                  }}
                                />
                              )}

                              {report.calendarType === CALENDAR_TYPE.DATE_RANGE_PICKER && (
                                <DateRangePickerField
                                  isOutsideRange={calDate => (
                                    !moment(calDate).isSameOrBefore(moment(), 'day')
                                  )}
                                  label="Report date range"
                                  name="period"
                                  required
                                  sublabel="Select a date range for the report. You cannot select a date period of more than 12 months."
                                />
                              )}

                            </div>
                            <div className="col-12 hint">
                              <strong>Disclaimer: </strong>
                              This report pulls information in a specific format. Please verify the
                              data within your account.
                            </div>
                          </div>
                        </Card.Body>
                      </Card>

                      <div className="mt-4 mb-8 w-100 d-flex justify-content-end">
                        <TDButton
                          bsSize={BS_SIZE.LARGE}
                          onClick={() => {
                            history.push(reportsUrl(orgAlias));
                          }}
                          label="Back"
                        />
                        <TDButton
                          type="submit"
                          variant={BS_STYLE.PRIMARY}
                          bsSize={BS_SIZE.LARGE}
                          disabled={submitting || invalid || (!date && !period)}
                          label="Download"
                        />
                      </div>
                    </form>
                  );
                }}
              />
            </TDApiConnected>
          </div>
        </div>
      </>
    );
  }
}

ReportView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  isModalOpen: PropTypes.bool,
  match: routerMatchContentsSpec.isRequired,
  report: dataReportSpec.isRequired,
};
ReportView.defaultProps = {
  isModalOpen: false,
};

const mapStateToProps = (state) => {
  const viewState = getViewState(state, ReportView.GetComponentName());
  return {
    isModalOpen: getIsModalOpen(state, CSV_INFO_MODAL),
    report: viewState.item,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});
const ReportViewConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportView);

export default withRouter(ReportViewConnect);
