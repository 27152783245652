import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ButtonGroup, Card, Dropdown } from 'react-bootstrap';

import { selectProfile } from 'accounts/assets/js/reducers/auth';
import AttachmentsList from 'core/assets/js/components/AttachmentsList.jsx';
import ConfirmationButton from 'core/assets/js/components/ConfirmationButton.jsx';
import MembersPreview from 'core/assets/js/components/MembersPreview.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import RatingScore from 'core/assets/js/components/RatingScore.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, DATETIME_FORMAT_ISO, ICON, IMG_SIZE } from 'core/assets/js/constants';
import { getIsModalOpen, modalOpenAC, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { fetchViewDS, getViewState } from 'core/assets/js/ducks/view';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { routerMatchSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { formatDate, parseDate } from 'core/assets/js/lib/utils';
import { postCreateChat } from 'people/assets/js/data-services/form';
import { orgPeopleMessagingUrl } from 'people/urls';
import { generateApplicationStatusLabel } from 'projects/assets/js/lib/helpers';
import ProjectApplicationCancelModalForm, { CANCEL_MODAL_ID } from 'projects/assets/js/components/ProjectApplicationCancelModalForm.jsx';
import ProjectApplicationRejectModalForm, { REJECT_MODAL_ID } from 'projects/assets/js/components/ProjectApplicationRejectModalForm.jsx';
import ProjectBriefDescription from 'projects/assets/js/components/ProjectBriefDescription.jsx';
import {
  PROJECT_APPLICATION_STATUS, PROJECT_APPLICATION_STATUS_CLASS,
} from 'projects/assets/js/constants';
import {
  acceptProjectApplicationDS,
  rejectProjectApplicationDS,
  cancelProjectApplicationDS,
} from 'projects/assets/js/data-services/opportunities';
import { projectSpec, projectApplicationSpec } from 'projects/assets/js/lib/objectSpecs';
import {
  projectOpportunitiesUrl,
  projectApplicationViewApiUrl,
  projectApplicationEditUrl,
} from 'projects/urls';
import RateAmount from 'rates/assets/js/components/RateAmount.jsx';

const ProjectApplicationView = ({
  dispatch, match, history, project, application,
  viewerId, isRejectionModalOpen, isCancellationModalOpen,
}) => {
  const isThemSelf = viewerId === application.userCard.user.id;
  const { orgAlias } = match.params;

  const {
    allowedActions: { canReviewApplication, canUpdateApplication },
    attachments,
    createdAt,
    id: applicationId,
    message,
    rateAmount,
    status,
    statusLabel,
    statusReason,
    userCard: {
      numReviews,
      rating,
      user: { profile: { avatar, jobTitle, name } },
    },
    userId,
  } = application;

  const {
    allowedActions: { canReApply },
    applicants,
    applicationsCount,
    created_at,
    currency_symbol: currencySymbol,
    deadline,
    id: projectId,
    max_applicants,
    owner_name,
    rateGuideUnit,
    title,
  } = project;

  const onCancel = values => dispatch(
    cancelProjectApplicationDS(orgAlias, project.id, application.id, values),
  )
    .then(async () => {
      dispatch(modalCloseAC(CANCEL_MODAL_ID));
      dispatch(fetchViewDS({
        componentName: 'ProjectApplicationView',
        url: projectApplicationViewApiUrl(orgAlias, project.id, application.id),
      }));
    });

  const onAccept = () => dispatch(acceptProjectApplicationDS(orgAlias, project.id, application.id))
    .then(async () => {
      dispatch(fetchViewDS({
        componentName: 'ProjectApplicationView',
        url: projectApplicationViewApiUrl(orgAlias, project.id, application.id),
      }));
    });

  const onReject = values => dispatch(
    rejectProjectApplicationDS(orgAlias, project.id, application.id, values),
  )
    .then(async () => {
      dispatch(modalCloseAC(REJECT_MODAL_ID));
      dispatch(fetchViewDS({
        componentName: 'ProjectApplicationView',
        url: projectApplicationViewApiUrl(orgAlias, project.id, application.id),
      }));
    });

  const breadcrumbs = [
    {
      title: 'Opportunities',
      url: projectOpportunitiesUrl(match.params.orgAlias),
    },
    {
      title: (project && project.title) || '',
      url: null,
    },
  ];

  const displayRateAmount = !!rateAmount && !!rateGuideUnit;

  return (
    <React.Fragment>
      <ContentHeader breadcrumbs={breadcrumbs} />

      <div className="page page--project-application">
        <div className="container">
          <div className="row">

            <div className="col-12 col-md-5 mb-4 mb-md-0">
              <Card>
                <Card.Header>
                  <aside className="discreet mb-3">
                    {`Posted on ${formatDate(created_at)} by ${owner_name}`}
                  </aside>
                  {project.title}
                  {deadline && (
                    <aside className="discreet mt-2 mb-3">
                      {`Project deadline ${formatDate(deadline)}`}
                    </aside>
                  )}

                  <div className="d-flex align-items-center mt-3">
                    {applicationsCount > 0 && (
                      <div
                        className="project-opportunities-card__applicants d-flex align-items-center"
                      >
                        <div className="count mr-3">{`${applicationsCount} applicants:`}</div>
                        <MembersPreview
                          total={applicationsCount}
                          users={applicants.slice(0, 5).map(a => a.user)}
                        />
                      </div>
                    )}
                    {max_applicants > 0 && (
                      <span className="mr-4">
                        {`${applicationsCount || 0}/${max_applicants} applicants`}
                      </span>
                    )}
                  </div>
                </Card.Header>
                <Card.Body>
                  <ProjectBriefDescription project={project} truncateDescription />
                </Card.Body>
              </Card>
            </div>

            <div className="col-12 col-md-7">
              <Card>
                <Card.Body>
                  {isThemSelf && (
                    <div className="row pb-4">
                      <div className="col-6 text-left">Your application</div>
                      <div className="col-6 text-right">
                        <StatusTag
                          className="ml-auto my-n2"
                          label={generateApplicationStatusLabel(application)}
                          displayAsTag
                          statusClass={PROJECT_APPLICATION_STATUS_CLASS[status]}
                        />
                      </div>
                    </div>
                  )}
                  <div className={`row p${isThemSelf ? 'y' : 'b'}-5`}>
                    <div className="col-12 col-xl-6 d-flex align-items-center">
                      <ProfilePic
                        alt={name}
                        size={[IMG_SIZE.MEDIUM, IMG_SIZE.MEDIUM]}
                        url={avatar}
                      />
                      <div className="col pl-3 pr-0">
                        <div className="d-flex flex-column flex-md-row position-relative">
                          <div className="user-item__basic-info">
                            <div>{name}</div>
                            {jobTitle && (
                              <div
                                className="user-item__extra discreet truncate mt-2"
                                title={jobTitle}
                              >
                                {jobTitle}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={(
                        'col-12 col-xl-6 d-flex align-items-center justify-content-start '
                        + 'justify-content-xl-end mt-5 mt-xl-0'
                      )}
                    >
                      {canReApply && isThemSelf && (
                        <TDButton
                          floatRight
                          label="Reapply"
                          onClick={() => history.push(
                            projectApplicationEditUrl(orgAlias, projectId, applicationId),
                          )}
                          variant={BS_STYLE.PRIMARY}
                        />
                      )}
                      {canUpdateApplication && isThemSelf && (
                        <>
                          <Dropdown as={ButtonGroup} className="td-dropdown">
                            <TDButton
                              floatRight
                              label="Edit"
                              onClick={() => history.push(
                                projectApplicationEditUrl(orgAlias, projectId, applicationId),
                              )}
                              variant={BS_STYLE.PRIMARY}
                            />
                            <Dropdown.Toggle split variant={BS_STYLE.PRIMARY}>
                              <span className={ICON.CHEVRON_DOWN} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu alignRight>
                              <Dropdown.Item
                                className="text-secondary"
                                key="cancel-application3"
                                onClick={() => dispatch(modalOpenAC(CANCEL_MODAL_ID))}
                              >
                                Cancel application
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <ProjectApplicationCancelModalForm
                            application={application}
                            isOpen={isCancellationModalOpen}
                            onSubmit={onCancel}
                          />
                        </>
                      )}
                      {canReviewApplication && (
                        <>
                          <TDButton
                            btnIcon={ICON.COMMENTS}
                            className="px-3 mr-4"
                            onClick={() => {
                              dispatch(postCreateChat({
                                orgAlias,
                                pushDataAC: response => {
                                  history.push(orgPeopleMessagingUrl(orgAlias, response.id));
                                  return [];
                                },
                                values: {
                                  title: `Discuss opportunity "${title}"`, users: [userId],
                                },
                              }));
                            }}
                          />
                          <ConfirmationButton
                            buttonIcon={ICON.CHECKMARK}
                            buttonLabel="Accept"
                            buttonStyle={BS_STYLE.SUCCESS}
                            modalProps={{
                              body: (
                                <p>
                                  Are you sure you want to accept this application?
                                </p>
                              ),
                              cancelLabel: 'Close',
                              confirmLabel: 'Accept',
                              confirmStyle: BS_STYLE.SUCCESS,
                              heading: 'Accept application',
                              onConfirm: onAccept,
                            }}
                          />
                          <TDButton
                            btnIcon={ICON.CROSS}
                            label="Reject"
                            onClick={() => dispatch(modalOpenAC(REJECT_MODAL_ID))}
                            variant={BS_STYLE.DANGER}
                          />
                          <ProjectApplicationRejectModalForm
                            application={application}
                            isOpen={isRejectionModalOpen}
                            onSubmit={onReject}
                          />
                        </>
                      )}
                      {!canReviewApplication && !isThemSelf && (
                        <StatusTag
                          className="ml-auto"
                          displayAsTag
                          label={statusLabel}
                          statusClass={PROJECT_APPLICATION_STATUS_CLASS[status]}
                        />
                      )}
                    </div>
                  </div>
                  <div className="row p-5">
                    {`Applied ${parseDate(createdAt, DATETIME_FORMAT_ISO).fromNow()}`}
                  </div>
                  {(displayRateAmount && numReviews > 0) && (
                    <div className="row justify-content-center py-5">
                      {displayRateAmount && (
                        <div className="col-12 col-md-6 text-center mb-5 mb-md-0">
                          <div className="d-flex flex-column">
                            <div className="font-weight-bold">
                              <RateAmount
                                amount={rateAmount}
                                showRateUnitDiscreetly
                                unit={rateGuideUnit}
                                symbol={currencySymbol}
                              />
                            </div>
                            <div className="discreet">Proposed rate</div>
                          </div>
                        </div>
                      )}
                      <div className="col-12 col-md-6 text-center">
                        <RatingScore numReviews={numReviews} rating={rating} />
                      </div>
                    </div>
                  )}
                  <div className="font-weight-bold my-5">Application message</div>
                  <p className="mb-0">{message}</p>
                  {statusReason && (
                    <div className="mt-5">
                      <strong>
                        { status === PROJECT_APPLICATION_STATUS.CANCELLED
                          ? 'Cancellation reason'
                          : 'Rejection reason'
                        }
                      </strong>
                      <blockquote className="py-2 mt-3 mb-0">
                        {statusReason}
                      </blockquote>
                    </div>
                  )}
                  {attachments && attachments.length > 0 && (
                    <div className="mt-5">
                      <AttachmentsList attachments={attachments} />
                    </div>
                  )}
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ProjectApplicationView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: routerMatchSpec.isRequired,
  history: routerHistorySpec.isRequired,
  project: projectSpec,
  application: projectApplicationSpec,
  viewerId: PropTypes.number.isRequired,
  isRejectionModalOpen: PropTypes.bool,
  isCancellationModalOpen: PropTypes.bool,
  statusReason: PropTypes.string,
};
ProjectApplicationView.defaultProps = {
  project: null,
  application: null,
  isRejectionModalOpen: false,
  isCancellationModalOpen: false,
  statusReason: null,
};

const mapStateToProps = (state) => {
  const applicationViewState = getViewState(state, 'ProjectApplicationView');
  const viewerProfile = selectProfile(state);
  const isRejectionModalOpen = getIsModalOpen(state, REJECT_MODAL_ID);
  const isCancellationModalOpen = getIsModalOpen(state, CANCEL_MODAL_ID);

  return {
    application: applicationViewState.item.application,
    project: applicationViewState.item.project,
    viewerId: viewerProfile ? viewerProfile.userId : null,
    isRejectionModalOpen,
    isCancellationModalOpen,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectApplicationFormViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectApplicationView);

const ProjectApplicationFormViewApiConnected = withTDApiConnected({
  fetchData: ({
    dispatch, params, url, authedAxios, componentName, querystring,
  }) => dispatch(fetchViewDS({
    authedAxios, componentName, querystring,
    url: projectApplicationViewApiUrl(
      params.orgAlias,
      params.projectId,
      params.applicationId,
      url,
    ),
  })),
  duck: 'view',
  storeKey: 'ProjectApplicationView',
  skeletonComponent: null,
})(ProjectApplicationFormViewConnected);

export default withRouter(ProjectApplicationFormViewApiConnected);
