import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { orgUserProfileUrl } from 'people/urls';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import { getDisplayRate } from 'rates/assets/js/lib/utils';
// import TaskProgressBar from 'projects/assets/js/components/TaskProgressBar.jsx';
import TagList from 'core/assets/js/components/TagList.jsx';
import { formatDate } from 'core/assets/js/lib/utils';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { orgSpec, userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import {
  selectActiveOrg, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import { getEffectiveTaskInfo } from 'projects/assets/js/lib/helpers';
import { TASK_TABS, ICON, IMG_SIZE, CURRENCY_SYMBOL, USER_TYPE } from 'core/assets/js/constants';
import { TASK_STATUS, TASK_ASSIGNMENT_STATUS } from 'projects/assets/js/constants';
import { projectTaskSpec } from 'projects/assets/js/lib/objectSpecs';
import { projectViewTaskUrl } from 'projects/urls';
import MembersPreview from 'core/assets/js/components/MembersPreview.jsx';

const DISABLED_ASSIGNMENT_STATUSES = [
  TASK_ASSIGNMENT_STATUS.REMOVED,
  TASK_ASSIGNMENT_STATUS.DECLINED,
  TASK_ASSIGNMENT_STATUS.LEFT,
];

const DISABLED_TASK_STATUSES = [
  TASK_STATUS.COMPLETED,
  TASK_STATUS.STOPPED,
  TASK_STATUS.REJECTED,
];

const ACTIVE_TASK_STATUSES = [
  TASK_STATUS.DRAFT,
  TASK_STATUS.PENDING,
  TASK_STATUS.INPROGRESS,
];

const TaskCard = ({ activeOrg, activeUserCard, className, isManager, item }) => {
  const {
    id, projectId, deadline, assignments, info, attachments, ownerName, ownerId, tags,
  } = item;
  const profileUrl = orgUserProfileUrl(activeOrg.alias, USER_TYPE.MANAGER, ownerId);
  const completedItemsCount = info && info.completedItemsCount ? info.completedItemsCount : 0;
  const totalItemsCount = info && info.totalItemsCount ? info.totalItemsCount : 0;
  const classNames = ['task-card'];

  if (className) {
    classNames.push(className);
  }

  const assignment = assignments
    ? assignments.find(a => a.user.id === activeUserCard.user.id)
    : null;

  const isAssignedToCurrentUser = (
    !!assignment && assignment.status === TASK_ASSIGNMENT_STATUS.ACCEPTED
  );

  const acceptedAssignments = assignments
    ? assignments.filter(a => a.status === TASK_ASSIGNMENT_STATUS.ACCEPTED)
    : [];

  // We have multiple assignments per task, which means that the task status should be different
  // for users that are accepted assignees than the ones that are eg. rejected
  const {
    label: effectiveLabel, /* progress: taskProgress, */status, statusClass: taskStatusClass,
  } = getEffectiveTaskInfo(activeOrg, item, { assignment });

  if (item.deleted) {
    classNames.push('task-card--disabled');
  } else if (assignment && DISABLED_ASSIGNMENT_STATUSES.includes(assignment.status)) {
    classNames.push('task-card--disabled');
  } else if (DISABLED_TASK_STATUSES.includes(status)) {
    classNames.push('task-card--disabled');
  }

  let effectiveTaskStatusLabel = effectiveLabel;
  if (isAssignedToCurrentUser) {
    if (deadline && ACTIVE_TASK_STATUSES.includes(status)) {
      const daysRemaining = moment(deadline).diff(moment(), 'days');

      if (daysRemaining > 0) {
        effectiveTaskStatusLabel = `${daysRemaining} days remaining`;
      } else if (daysRemaining === 0) {
        effectiveTaskStatusLabel = 'Due today';
      }
    }
  }

  return (
    <Card>
      <Card.Body>
        <div className="row">
          <div data-testid="my-task-card-title" className="task-card__basic-info d-flex flex-column">
            <div className="col-12 col-sm-8">
              <h3 className="mt-0 mb-4">
                <Link
                  to={projectViewTaskUrl(activeOrg.alias, projectId, item.id, TASK_TABS.DASHBOARD)}
                  className="text-dark"
                >
                  {item.title}
                </Link>
              </h3>
            </div>
          </div>

          <div className="col-12 col-sm-4 d-none d-sm-block">
            <div className="task-card__assignments d-flex justify-content-end align-items-center">
              { /* Show rate inline if only one assignment found */ }
              { !isEmpty(acceptedAssignments)
                && acceptedAssignments.length === 1
                && (
                  <span className="pt-3 mt-n3 profile-pic-wrapper">
                    <ProfilePic
                      url={acceptedAssignments[0].user.profile.avatar}
                      alt={acceptedAssignments[0].user.profile.name}
                      size={[IMG_SIZE.XSMALL, IMG_SIZE.XSMALL]}
                    />

                    <StatusTag
                      hideTitle
                      className="ml-2"
                      statusClass="default"
                      customAttrs={{
                        'data-testid': 'assignment-rate',
                      }}
                      label={getDisplayRate(
                        acceptedAssignments[0].rate,
                        acceptedAssignments[0].rate_unit,
                        { currency: CURRENCY_SYMBOL[acceptedAssignments[0].currency] },
                      )}
                      hideDot
                    />
                  </span>
                )}

              { /* Show rate in tooltip if assignments are more than one */ }
              { !isEmpty(acceptedAssignments)
                && acceptedAssignments.length > 1
                  && (
                    <MembersPreview
                      total={acceptedAssignments.length}
                      url={projectViewTaskUrl(activeOrg.alias, projectId, id)}
                      users={acceptedAssignments.slice(0, 5).map(uc => uc.user)}
                    />
                  )
              }
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            { ownerName && (
              <React.Fragment>
                <span className="discreet project-list__item--label">
                  Task owner
                </span>
                <span>
                  <Link className="text-dark" to={profileUrl}>
                    {ownerName}
                  </Link>
                </span>
              </React.Fragment>
            )}

            { deadline && (
              <span className="project-list__item__deadline ml-4">
                <span className="discreet project-list__item--label">
                  Deadline
                </span>
                <span>{ formatDate(deadline, 'DD MMM, YYYY') }</span>
              </span>
            )}
          </div>
        </div>

        <hr />

        <div className="row align-items-center">
          <div className="col-12 col-sm-7 col-lg-8 mb-3 mb-md-0">
            {tags && tags.length > 0 && (
              <TagList tags={tags} />
            )}

            {isManager && totalItemsCount > 0 && (
              <div className="task-card__completion mr-4">
                <span className={`${ICON.SUCCESS} mr-2`} />
                {completedItemsCount}
                {'/'}
                {totalItemsCount}
              </div>
            )}

            {attachments && attachments.length > 0 && (
              <div className="task-card__attachments">
                <span className={`${ICON.ATTACHMENT} mr-2 discreet`} />
                {attachments.length}
              </div>
            )}
          </div>

          <div className="col-12 col-sm-5 col-lg-4 text-right">
            <div className="project-list__item--progress pt-1">
              <div className="project-list__item--status">
                <StatusTag
                  statusClass={taskStatusClass}
                  label={effectiveTaskStatusLabel}
                  title={effectiveTaskStatusLabel}
                />
              </div>

              {/* <div className="project-list__item--progress__percentage">
                { taskProgress > 0 && `${taskProgress}%` }
              </div> */}
            </div>

            {/* <TaskProgressBar
              id={id}
              progress={taskProgress}
              statusClass={taskStatusClass}
              className="task-progress-bar--thick mt-2"
            /> */}
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

TaskCard.propTypes = {
  activeOrg: orgSpec.isRequired,
  activeUserCard: userCardSpec.isRequired,
  className: PropTypes.string,
  isManager: PropTypes.bool,
  item: projectTaskSpec.isRequired,
};

TaskCard.defaultProps = {
  className: null,
  isManager: false,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  activeUserCard: selectActiveUserCard(state),
  isManager: getHasOrgAccess(state)({ requireManager: true }),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const TaskCardConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskCard);

export default TaskCardConnected;
