import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { map, pick } from 'lodash';
import queryString from 'query-string';

import {
  REPORT_TYPES,
  REPORT_TYPES_LABEL,
  INVOICE_STATUS_OPTIONS,
  RAISED_BY_OPTIONS, INVOICE_STATUS,
} from 'finance/assets/js/constants';
import {
  PURCHASE_ORDER_STATUS_OPTIONS,
  SERVICE_ORDER_INVOICED,
  SERVICE_ORDER_PAID,
  SERVICE_ORDER_RESUBMITTED,
  SERVICE_ORDER_RESUBMITTED_LABEL,
  SERVICE_ORDER_STATUS,
  SERVICE_ORDER_STATUS_LABEL,
} from 'projects/assets/js/constants';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import SearchForm from 'core/assets/js/components/SearchForm.jsx';
import {
  CURRENCY, CURRENCY_DESCRIPTION, CURRENCY_LABEL, CURRENCY_SYMBOL,
} from 'core/assets/js/constants';
import {
  selectActiveOrg, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import { orgSpec, userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import SelectableListFilterField from 'core/assets/js/components/FilterFields/SelectableListFilterField.jsx';
import DateRangeFilterField from 'core/assets/js/components/FilterFields/DateRangeFilterField.jsx';
import RangeFilterField from 'core/assets/js/components/FilterFields/RangeFilterField.jsx';


class ReportSearch extends React.Component {
  constructor(props) {
    super(props);
    this.getSearchSpec = this.getSearchSpec.bind(this);
    this.getInitialValues = this.getInitialValues.bind(this);
  }

  getInitialValues() {
    const { location } = this.props;
    const searchSpec = this.getSearchSpec();
    const filtersList = map(searchSpec.filters, 'paramName');

    let values = queryString.parse(location.search);
    values = pick(values, ['kw'].concat(filtersList));
    return values;
  }

  getSearchSpec() {
    const { activeUserCard, activeOrg, amountLimits, reportType } = this.props;

    const spec = {
      searchTerm: {
        paramName: 'kw',
        component: InputField,
        placeholder: `Search ${REPORT_TYPES_LABEL[reportType]}s`,
      },
      filters: [],
    };

    if (
      [
        REPORT_TYPES.EXPENSE, REPORT_TYPES.WORKSHEET, REPORT_TYPES.PRO_FORMA_INVOICE,
      ].includes(reportType)
    ) {
      spec.filters = [
        {
          label: 'Status',
          paramName: 'status',
          fieldComponent: SelectableListFilterField,
          options: [
            {
              text: SERVICE_ORDER_STATUS_LABEL[SERVICE_ORDER_STATUS.RAISED],
              value: SERVICE_ORDER_STATUS.RAISED,
            },
            {
              text: SERVICE_ORDER_STATUS_LABEL[SERVICE_ORDER_STATUS.AMENDMENT_REQUESTED],
              value: SERVICE_ORDER_STATUS.AMENDMENT_REQUESTED,
            },
            { text: SERVICE_ORDER_RESUBMITTED_LABEL, value: SERVICE_ORDER_RESUBMITTED },
            {
              text: SERVICE_ORDER_STATUS_LABEL[SERVICE_ORDER_STATUS.CONFIRMED],
              value: SERVICE_ORDER_STATUS.CONFIRMED,
            },
            {
              text: SERVICE_ORDER_STATUS_LABEL[SERVICE_ORDER_STATUS.REJECTED],
              value: SERVICE_ORDER_STATUS.REJECTED,
            },
            {
              text: SERVICE_ORDER_STATUS_LABEL[SERVICE_ORDER_STATUS.UNRESOLVED],
              value: SERVICE_ORDER_STATUS.UNRESOLVED,
            },
            ...(!activeUserCard.userRole.isAnyManager ? [] : [{
              text: SERVICE_ORDER_STATUS_LABEL[SERVICE_ORDER_STATUS.NOT_ENOUGH_FUNDS],
              value: SERVICE_ORDER_STATUS.NOT_ENOUGH_FUNDS,
            }]),
            {
              text: SERVICE_ORDER_STATUS_LABEL[SERVICE_ORDER_STATUS.CANCELLED],
              value: SERVICE_ORDER_STATUS.CANCELLED,
            },
            {
              text: SERVICE_ORDER_STATUS_LABEL[SERVICE_ORDER_STATUS.APPROVED],
              value: SERVICE_ORDER_STATUS.APPROVED,
            },
            { text: 'Invoiced', value: SERVICE_ORDER_INVOICED },
            { text: 'Paid', value: SERVICE_ORDER_PAID },
            {
              text: SERVICE_ORDER_STATUS_LABEL[SERVICE_ORDER_STATUS.VOID],
              value: SERVICE_ORDER_STATUS.VOID,
            },
          ],
          multiple: true,
        },
        {
          label: 'Date Created',
          paramName: 'date',
          fieldComponent: DateRangeFilterField,
          className: 'col-6 col-sm-4 col-md-4 col-lg-3 mb-4',
        },
      ];
    }

    if (reportType === REPORT_TYPES.INVOICE) {
      spec.filters = [
        {
          label: 'Status',
          paramName: 'status',
          fieldComponent: SelectableListFilterField,
          options: INVOICE_STATUS_OPTIONS.filter(option => option.value !== INVOICE_STATUS.PREVIEW),
          multiple: true,
        },
        {
          label: 'Raised by',
          paramName: 'raised_by',
          fieldComponent: SelectableListFilterField,
          options: RAISED_BY_OPTIONS,
          multiple: true,
        },
        {
          label: 'Posted Date',
          paramName: 'date',
          fieldComponent: DateRangeFilterField,
          className: 'col-6 col-sm-4 col-md-4 col-lg-3 mb-4',
        },
      ];
    }

    if ([REPORT_TYPES.EXPENSE, REPORT_TYPES.INVOICE, REPORT_TYPES.WORKSHEET].includes(reportType)) {
      spec.filters.push(
        {
          fieldComponent: RangeFilterField,
          label: 'Amount',
          paramName: 'amount',
          symbol: CURRENCY_SYMBOL[activeOrg.currency],
          ...amountLimits,
        },
        {
          label: 'Currency',
          paramName: 'currency',
          fieldComponent: SelectableListFilterField,
          options: Object.values(CURRENCY).map(value => ({
            text: [
              CURRENCY_LABEL[value], CURRENCY_DESCRIPTION[value], `(${CURRENCY_SYMBOL[value]})`,
            ].join(' '),
            value,
          })),
          multiple: true,
        },
      );
    }

    if (reportType === REPORT_TYPES.PURCHASE_ORDER) {
      spec.filters = [
        {
          label: 'Status',
          paramName: 'status',
          fieldComponent: SelectableListFilterField,
          options: PURCHASE_ORDER_STATUS_OPTIONS,
          multiple: true,
        },
        {
          label: 'Date Created',
          paramName: 'createdAt',
          fieldComponent: DateRangeFilterField,
          className: 'col-6 col-sm-4 col-md-4 col-lg-3 gutter-bottom',
        },
        {
          label: 'Amount',
          paramName: 'budget',
          fieldComponent: RangeFilterField,
          ...amountLimits,
          symbol: CURRENCY_SYMBOL[activeOrg.currency],
        },
      ];
    }

    return spec;
  }

  render() {
    const { onFiltersToggle, filtersOpen } = this.props;

    return (
      <SearchForm
        data-testid="report-search-form"
        name="reports"
        initialValues={this.getInitialValues()}
        searchSpec={this.getSearchSpec()}
        onFiltersToggle={onFiltersToggle}
        filtersOpen={filtersOpen}
      />
    );
  }
}

ReportSearch.propTypes = {
  activeOrg: orgSpec.isRequired,
  activeUserCard: userCardSpec.isRequired,
  amountLimits: PropTypes.object,
  filtersOpen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  reportType: PropTypes.string.isRequired,
};

ReportSearch.defaultProps = {
  amountLimits: {},
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  activeUserCard: selectActiveUserCard(state),
});

const ReportSearchConnect = connect(
  mapStateToProps,
)(ReportSearch);

export default withRouter(ReportSearchConnect);
