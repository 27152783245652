import { ICON } from 'core/assets/js/constants';

export const PERMISSIONS = {
  CAN_DELETE_PROVIDER_RATES: 'can-delete-provider-rates',
  CAN_CREATE_EDIT_PROVIDER_RATES: 'can-edit-provider-rates',
  CAN_SET_PROVIDER_DEFAULT_RATE: 'can-set-provider-default-rate',
  CAN_INVITE_MANAGERS: 'can-invite-managers',
  CAN_INVITE_PROVIDERS: 'can-invite-providers',
  CAN_MANAGE_ROLES: 'can-manage-roles',
  CAN_REVIEW_ONBOARDING_FORMS: 'can-review-onboarding-forms',
  CAN_BULK_IMPORT_USERS: 'can-bulk-import-users',
  CAN_VIEW_1099_CONTRACTORS: 'can-view-1099-contractors',
  CAN_FILE_1099_REPORTS: 'can-file-1099-reports',
  CAN_REVIEW_IRS_TAXPAYER_INFORMATION_FORMS: 'can-review-irs-taxpayer-information-forms',
  CAN_MANAGE_ALL_PROJECTS: 'can-manage-all-projects',
  CAN_MANAGE_INVOICE_CAPS: 'can-manage-invoice-caps',
  CAN_MANAGE_ORGANIZATION_SETTINGS: 'can-manage-organization-settings',
  CAN_SUGGEST_TASK_RATES: 'can-suggest-task-rates',
  CAN_VIEW_PROVIDER_TAX_INFORMATION: 'can-view-provider-tax-information',
  CAN_VIEW_CLASSIFICATION_QUESTIONNAIRE_SUBMISSIONS: (
    'can-view-classification-questionnaire-submissions'
  ),
  CAN_CREATE_TASKS: 'can-create-tasks',
  CAN_DEACTIVATE_PROVIDERS: 'can-deactivate-providers',
  CAN_REACTIVATE_PROVIDERS: 'can-reactivate-providers',
  CAN_REVIEW_ALL_WORKSHEETS: 'can-review-all-worksheets',
  CAN_REVIEW_WORKSHEETS: 'can-review-worksheets',
  CAN_REVIEW_EXPENSES: 'can-review-expenses',
  CAN_REVIEW_ALL_EXPENSES: 'can-review-all-expense',
  CAN_REVIEW_ALL_PROFORMA_INVOICES: 'can-review-all-proforma-invoices',
  CAN_CREATE_PROJECTS: 'can-create-projects',
  CAN_ACCESS_REPORTS: 'can-access-reports',
  // Invoices
  CAN_VIEW_INVOICES: 'can-view-invoices',
  CAN_PROCESS_INVOICES: 'can-process-invoices',
  CAN_VIEW_PROVIDER_PAYMENT_DETAILS: 'can-view-provider-payment-details',
  CAN_DEACTIVATE_MANAGERS: 'can-deactivate-managers',
  CAN_REACTIVATE_MANAGERS: 'can-reactivate-managers',
  CAN_VIEW_ORGANIZATION_SETUP: 'can-view-organization-setup',
  CAN_MANAGE_PAYMENTS_SETTINGS: 'can-manage-payments-settings',
};

export const PERMISSIONS_VALUES = Object.values(PERMISSIONS);

export const PERMISSIONS_LABEL = {
  [PERMISSIONS.CAN_DELETE_PROVIDER_RATES]: 'Can delete provider rates',
  [PERMISSIONS.CAN_CREATE_EDIT_PROVIDER_RATES]: 'Can create/edit provider rates',
  [PERMISSIONS.CAN_SET_PROVIDER_DEFAULT_RATE]: 'Can set Providers default rate',
  [PERMISSIONS.CAN_INVITE_MANAGERS]: 'Can invite managers',
  [PERMISSIONS.CAN_INVITE_PROVIDERS]: 'Can invite providers',
  [PERMISSIONS.CAN_MANAGE_ROLES]: 'Can manage roles',
  [PERMISSIONS.CAN_REVIEW_ONBOARDING_FORMS]: 'Can review onboarding forms',
  [PERMISSIONS.CAN_BULK_IMPORT_USERS]: 'Can bulk import Users',
  [PERMISSIONS.CAN_VIEW_1099_CONTRACTORS]: 'Can view eligible 1099 contractors',
  [PERMISSIONS.CAN_FILE_1099_REPORTS]: 'Can file 1099 reports',
  [PERMISSIONS.CAN_REVIEW_IRS_TAXPAYER_INFORMATION_FORMS]: 'Can review IRS taxpayer information forms',
  [PERMISSIONS.CAN_MANAGE_ALL_PROJECTS]: 'Manage all projects',
  [PERMISSIONS.CAN_MANAGE_INVOICE_CAPS]: 'Can manage invoice caps for users',
  [PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS]: 'Can manage organization settings',
  [PERMISSIONS.CAN_SUGGEST_TASK_RATES]: 'Can suggest task rates',
  [PERMISSIONS.CAN_VIEW_PROVIDER_TAX_INFORMATION]: 'Can view provider tax information',
  [PERMISSIONS.CAN_VIEW_CLASSIFICATION_QUESTIONNAIRE_SUBMISSIONS]: (
    'Can view classification questionnaire submissions'
  ),
  [PERMISSIONS.CAN_CREATE_TASKS]: 'Create tasks',
  [PERMISSIONS.CAN_DEACTIVATE_PROVIDERS]: 'Can deactivate providers',
  [PERMISSIONS.CAN_REACTIVATE_PROVIDERS]: 'Can reactivate providers',
  [PERMISSIONS.CAN_CREATE_PROJECTS]: 'Create projects',
  [PERMISSIONS.CAN_ACCESS_REPORTS]: 'Access reports',
  [PERMISSIONS.CAN_VIEW_INVOICES]: 'View invoices',
  [PERMISSIONS.CAN_PROCESS_INVOICES]: 'Action invoices',
  [PERMISSIONS.CAN_VIEW_PROVIDER_PAYMENT_DETAILS]: 'View provider payment details',
  [PERMISSIONS.CAN_REVIEW_ALL_WORKSHEETS]: 'Review all worksheets',
  [PERMISSIONS.CAN_REVIEW_WORKSHEETS]: 'Review worksheets',
  [PERMISSIONS.CAN_REVIEW_EXPENSES]: 'Review expenses',
  [PERMISSIONS.CAN_REVIEW_ALL_EXPENSES]: 'Review all expenses',
  [PERMISSIONS.CAN_REVIEW_ALL_PROFORMA_INVOICES]: 'Review proforma invoices',
  [PERMISSIONS.CAN_DEACTIVATE_MANAGERS]: 'Deactivate managers',
  [PERMISSIONS.CAN_REACTIVATE_MANAGERS]: 'Reactivate managers',
  [PERMISSIONS.CAN_VIEW_ORGANIZATION_SETUP]: 'View organisation setup',
  [PERMISSIONS.CAN_MANAGE_PAYMENTS_SETTINGS]: 'Manage payments settings',
};

export const PERMISSIONS_DESCRIPTION = {
  [PERMISSIONS.CAN_DELETE_PROVIDER_RATES]: "Can delete a Provider's rates.",
  [PERMISSIONS.CAN_CREATE_EDIT_PROVIDER_RATES]: "Can create & edit a Provider's rates.",
  [PERMISSIONS.CAN_SET_PROVIDER_DEFAULT_RATE]: 'Can set the default rate for Providers.',
  [PERMISSIONS.CAN_INVITE_MANAGERS]: 'Can invite new Managers to the Organisation.',
  [PERMISSIONS.CAN_INVITE_PROVIDERS]: 'Can invite new Providers to the Organisation.',
  [PERMISSIONS.CAN_MANAGE_ROLES]: 'Can manage Roles & Permissions.',
  [PERMISSIONS.CAN_REVIEW_ONBOARDING_FORMS]: 'Can review the onboarding forms of Providers.',
  [PERMISSIONS.CAN_BULK_IMPORT_USERS]: 'Can bulk import Providers to the Organisation from a CSV file.',
  [PERMISSIONS.CAN_VIEW_1099_CONTRACTORS]: 'Can access the 1099 filing tool in read-only mode and see the list of Providers that a 1099 will be filed for.',
  [PERMISSIONS.CAN_FILE_1099_REPORTS]: 'Can access the 1099 filing tool and has full control for reviewing W9s and filing 1099s.',
  [PERMISSIONS.CAN_REVIEW_IRS_TAXPAYER_INFORMATION_FORMS]: 'Can review the W9 forms submitted by Providers and decide whether a 1099 is required.',
  [PERMISSIONS.CAN_MANAGE_ALL_PROJECTS]: 'Update, stop, complete, and archive projects that other managers own',
  [PERMISSIONS.CAN_MANAGE_INVOICE_CAPS]: 'Can manage invoice caps for each Provider',
  [PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS]: 'Can manage the Organisation settings',
  [PERMISSIONS.CAN_SUGGEST_TASK_RATES]: (
    'Can suggest new rates for providers, when inviting them to tasks'
  ),
  [PERMISSIONS.CAN_VIEW_PROVIDER_TAX_INFORMATION]: 'Can view the Tax information for all Providers including their Tax Identification Number (TIN/SSN), as well as their uploaded tax documents and filed 1099s.',
  [PERMISSIONS.CAN_VIEW_CLASSIFICATION_QUESTIONNAIRE_SUBMISSIONS]: (
    'Can view classification questionnaire submissions'
  ),
  [PERMISSIONS.CAN_CREATE_TASKS]: 'The ability to create new tasks.',
  [PERMISSIONS.CAN_DEACTIVATE_PROVIDERS]: 'The ability to deactivate providers',
  [PERMISSIONS.CAN_REACTIVATE_PROVIDERS]: 'The ability to reactivate providers',
  [PERMISSIONS.CAN_CREATE_PROJECTS]: 'The ability to create new projects.',
  [PERMISSIONS.CAN_ACCESS_REPORTS]: 'View and export all reports',
  [PERMISSIONS.CAN_VIEW_INVOICES]: 'Read-only access to view all invoices',
  [PERMISSIONS.CAN_PROCESS_INVOICES]: 'Where possible, mark invoices as paid',
  [PERMISSIONS.CAN_VIEW_PROVIDER_PAYMENT_DETAILS]: 'View the configured payment information of providers',
  [PERMISSIONS.CAN_REVIEW_ALL_WORKSHEETS]: 'Review and action all worksheets for projects that other managers own',
  [PERMISSIONS.CAN_REVIEW_WORKSHEETS]: 'Review and action worksheets for the projects where the manager is part of the project team',
  [PERMISSIONS.CAN_REVIEW_EXPENSES]: 'Review and action expenses for the projects where the manager is part of the project team',
  [PERMISSIONS.CAN_REVIEW_ALL_EXPENSES]: 'Review and action all expenses for projects that other managers own',
  [PERMISSIONS.CAN_REVIEW_ALL_PROFORMA_INVOICES]: 'Review and action all proforma invoices',
  [PERMISSIONS.CAN_DEACTIVATE_MANAGERS]: 'Remove managers from the organization and reassign projects',
  [PERMISSIONS.CAN_REACTIVATE_MANAGERS]: 'Reactivate managers from the organization',
  [PERMISSIONS.CAN_VIEW_ORGANIZATION_SETUP]: 'View the TalentDesk subscription information',
  [PERMISSIONS.CAN_MANAGE_PAYMENTS_SETTINGS]: 'Manage the payment settings for the organization',
};

export const PERMISSIONS_GROUPS = {
  ONBOARDING: 'onboarding',
  RATES: 'rates',
  ROLES: 'roles',
  LEGAL: 'legal',
  INVOICING: 'invoicing',
  SETTINGS: 'settings',
};

export const PERMISSIONS_GROUPS_LABEL = {
  [PERMISSIONS_GROUPS.ONBOARDING]: 'Onboarding',
  [PERMISSIONS_GROUPS.RATES]: 'Rates',
  [PERMISSIONS_GROUPS.ROLES]: 'Roles and permissions',
  [PERMISSIONS_GROUPS.LEGAL]: 'Legal',
  [PERMISSIONS_GROUPS.INVOICING]: 'Invoicing',
  [PERMISSIONS_GROUPS.SETTINGS]: 'Settings',
};

export const PERMISSIONS_GROUPINGS = {
  [PERMISSIONS_GROUPS.ONBOARDING]: [
    PERMISSIONS.CAN_INVITE_MANAGERS,
    PERMISSIONS.CAN_INVITE_PROVIDERS,
    PERMISSIONS.CAN_REVIEW_ONBOARDING_FORMS,
    PERMISSIONS.CAN_BULK_IMPORT_USERS,
  ],
  [PERMISSIONS_GROUPS.RATES]: [
    PERMISSIONS.CAN_DELETE_PROVIDER_RATES,
    PERMISSIONS.CAN_CREATE_EDIT_PROVIDER_RATES,
    PERMISSIONS.CAN_SET_PROVIDER_DEFAULT_RATE,
  ],
  [PERMISSIONS_GROUPS.ROLES]: [
    PERMISSIONS.CAN_MANAGE_ROLES,
  ],
  [PERMISSIONS_GROUPS.LEGAL]: [
    PERMISSIONS.CAN_VIEW_1099_CONTRACTORS,
    PERMISSIONS.CAN_FILE_1099_REPORTS,
    PERMISSIONS.CAN_REVIEW_IRS_TAXPAYER_INFORMATION_FORMS,
    PERMISSIONS.CAN_VIEW_PROVIDER_TAX_INFORMATION,
    PERMISSIONS.CAN_VIEW_CLASSIFICATION_QUESTIONNAIRE_SUBMISSIONS,
  ],
  [PERMISSIONS_GROUPS.INVOICING]: [
    PERMISSIONS.CAN_MANAGE_INVOICE_CAPS,
  ],
  [PERMISSIONS_GROUPS.SETTINGS]: [
    PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS,
  ],
};

export const ROLE_TABS = {
  CONFIG: 'config',
  MEMBERS: 'members',
};

export const SYSTEM_ROLES = {
  SYSTEM_ADMIN: 'system-admin',
  FINANCIAL_CONTROLLER: 'financial-controller',
  PROJECT_MANAGER: 'project-manager',
};

export const SYSTEM_ROLES_LABEL = {
  [SYSTEM_ROLES.SYSTEM_ADMIN]: 'Owner',
  [SYSTEM_ROLES.FINANCIAL_CONTROLLER]: 'Financial Controller',
  [SYSTEM_ROLES.PROJECT_MANAGER]: 'Project Manager',
};

export const SYSTEM_ROLES_DESCRIPTION = {
  [SYSTEM_ROLES.SYSTEM_ADMIN]: 'Full access to all features',
  [SYSTEM_ROLES.FINANCIAL_CONTROLLER]: 'Ability to perform financial operations',
  [SYSTEM_ROLES.PROJECT_MANAGER]: 'Ability to fully manage projects and tasks',
};

export const SYSTEM_ROLES_ICONS = {
  [SYSTEM_ROLES.SYSTEM_ADMIN]: ICON.USER_GEAR_DUOTONE,
  [SYSTEM_ROLES.FINANCIAL_CONTROLLER]: ICON.CALCULATOR_DUOTONE,
  [SYSTEM_ROLES.PROJECT_MANAGER]: ICON.BAR_PROGRESS_DUOTONE,
};

export const PERMISSION_GROUPS = {
  TEAM: 'team',
  SETTINGS: 'settings',
  FINANCES: 'finances',
  LEGAL_AND_TAXES: 'legal-and-taxes',
  REPORTS: 'reports',
  PROJECTS_AND_TASKS: 'projects-and-tasks',
};

export const PERMISSION_GROUPS_LABEL = {
  [PERMISSION_GROUPS.TEAM]: 'Team',
  [PERMISSION_GROUPS.FINANCES]: 'Finances',
  [PERMISSION_GROUPS.SETTINGS]: 'Settings',
  [PERMISSION_GROUPS.LEGAL_AND_TAXES]: 'Legal & Taxes',
  [PERMISSION_GROUPS.REPORTS]: 'Reports',
  [PERMISSION_GROUPS.PROJECTS_AND_TASKS]: 'Projects & Tasks',
};

export const PERMISSION_SUBGROUPS = {
  PROVIDERS: 'providers',
  MANAGERS: 'managers',
  ROLES_AND_PERMISSIONS: 'roles-and-permissions',
  '1099_FILINGS': '1099-filings',
  CLASSIFICATION_QUESTIONNAIRE: 'classification-questionnaire',
  GENERAL: 'GENERAL',
  PROJECTS: 'projects',
  TASKS: 'tasks',
  REPORTS: 'reports',
  INVOICES: 'invoices',
  FINANCES: 'finances',
  SERVICE_ORDERS: 'service-orders',
};

export const PERMISSION_SUBGROUPS_LABEL = {
  [PERMISSION_SUBGROUPS.PROVIDERS]: 'Providers',
  [PERMISSION_SUBGROUPS.MANAGERS]: 'Managers',
  [PERMISSION_SUBGROUPS.ROLES_AND_PERMISSIONS]: 'Roles & Permissions',
  [PERMISSION_SUBGROUPS['1099_FILINGS']]: '1099 Filings',
  [PERMISSION_SUBGROUPS.CLASSIFICATION_QUESTIONNAIRE]: 'Classification Questionnaire',
  [PERMISSION_SUBGROUPS.GENERAL]: 'General',
  [PERMISSION_SUBGROUPS.PROJECTS]: 'Projects',
  [PERMISSION_SUBGROUPS.TASKS]: 'Tasks',
  [PERMISSION_SUBGROUPS.REPORTS]: 'Reports',
  [PERMISSION_SUBGROUPS.INVOICES]: 'Invoices',
  [PERMISSION_SUBGROUPS.FINANCES]: 'Finances',
  [PERMISSION_SUBGROUPS.SERVICE_ORDERS]: 'Service Orders',
};

export const PERMISSION_SUBGROUPS_DESCRIPTION = {
  [PERMISSION_SUBGROUPS.PROVIDERS]: 'Manage who can view or change provider settings',
  [PERMISSION_SUBGROUPS.MANAGERS]: 'Manage who can view or change manager settings',
  [PERMISSION_SUBGROUPS.ROLES_AND_PERMISSIONS]: 'Manage who can view or change roles and permissions',
  [PERMISSION_SUBGROUPS['1099_FILINGS']]: 'Manage who can view or change 1099 filings',
  [PERMISSION_SUBGROUPS.CLASSIFICATION_QUESTIONNAIRE]: 'Manage who can view or change classification questionnaire',
  [PERMISSION_SUBGROUPS.GENERAL]: 'Manage who can view or change organization settings',
  [PERMISSION_SUBGROUPS.PROJECTS]: 'Manage who can view or change project settings',
  [PERMISSION_SUBGROUPS.TASKS]: 'Manage who can view or change task settings',
  [PERMISSION_SUBGROUPS.REPORTS]: 'Manage who can view or change report settings',
  [PERMISSION_SUBGROUPS.SERVICE_ORDERS]: 'Manage who can view or action service orders',
  [PERMISSION_SUBGROUPS.INVOICES]: 'Manage who can view or change invoice settings',
  [PERMISSION_SUBGROUPS.FINANCES]: 'Manage who can view or change finance settings',
};

export const PERMISSION_SUBGROUPS_ICONS = {
  [PERMISSION_SUBGROUPS.PROVIDERS]: ICON.USER_DUOTONE,
  [PERMISSION_SUBGROUPS.MANAGERS]: ICON.USER_CROWN_DUOTONE,
  [PERMISSION_SUBGROUPS.ROLES_AND_PERMISSIONS]: ICON.KEY_DUOTONE,
  [PERMISSION_SUBGROUPS['1099_FILINGS']]: ICON.FILE_DUOTONE,
  [PERMISSION_SUBGROUPS.CLASSIFICATION_QUESTIONNAIRE]: ICON.CLIPBOARD_LIST_CHECK_DUOTONE,
  [PERMISSION_SUBGROUPS.GENERAL]: ICON.GEAR_DUOTONE,
  [PERMISSION_SUBGROUPS.SERVICE_ORDERS]: ICON.FILE_INVOICE_DUOTONE,
  [PERMISSION_SUBGROUPS.PROJECTS]: ICON.BAR_PROGRESS_DUOTONE,
  [PERMISSION_SUBGROUPS.TASKS]: ICON.LIST_CHECK_DUOTONE,
  [PERMISSION_SUBGROUPS.REPORTS]: ICON.FILE_CHART_PIE_DUOTONE,
  [PERMISSION_SUBGROUPS.INVOICES]: ICON.FILE_INVOICE_DUOTONE,
  [PERMISSION_SUBGROUPS.FINANCES]: ICON.CALCULATOR_DUOTONE,
};

export const SYSTEM_ROLES_PERMISSION_GROUPS = {
  [SYSTEM_ROLES.PROJECT_MANAGER]: [
    {
      id: PERMISSION_GROUPS.TEAM,
      subgroups: [
        { id: PERMISSION_SUBGROUPS.PROVIDERS, permissions: [PERMISSIONS.CAN_INVITE_PROVIDERS] },
        { id: PERMISSION_SUBGROUPS.MANAGERS, permissions: [PERMISSIONS.CAN_INVITE_MANAGERS] },
      ],
    },
    {
      id: PERMISSION_GROUPS.PROJECTS_AND_TASKS,
      subgroups: [
        {
          id: PERMISSION_SUBGROUPS.PROJECTS,
          permissions: [PERMISSIONS.CAN_CREATE_PROJECTS],
        },
        {
          id: PERMISSION_SUBGROUPS.TASKS,
          permissions: [PERMISSIONS.CAN_CREATE_TASKS, PERMISSIONS.CAN_SUGGEST_TASK_RATES],
        },
      ],
    },
    {
      id: PERMISSION_GROUPS.FINANCES,
      subgroups: [
        {
          id: PERMISSION_SUBGROUPS.SERVICE_ORDERS,
          permissions: [
            PERMISSIONS.CAN_REVIEW_WORKSHEETS,
            PERMISSIONS.CAN_REVIEW_EXPENSES,
          ],
        },
      ],
    },
  ],
  [SYSTEM_ROLES.FINANCIAL_CONTROLLER]: [
    {
      id: PERMISSION_GROUPS.TEAM,
      subgroups: [
        {
          id: PERMISSION_SUBGROUPS.PROVIDERS,
          permissions: [
            PERMISSIONS.CAN_DELETE_PROVIDER_RATES,
            PERMISSIONS.CAN_CREATE_EDIT_PROVIDER_RATES,
            PERMISSIONS.CAN_SET_PROVIDER_DEFAULT_RATE,
            PERMISSIONS.CAN_VIEW_PROVIDER_PAYMENT_DETAILS,
          ],
        },
      ],
    },
    {
      id: PERMISSION_GROUPS.REPORTS,
      subgroups: [
        {
          id: PERMISSION_SUBGROUPS.REPORTS,
          permissions: [PERMISSIONS.CAN_ACCESS_REPORTS],
        },
      ],
    },
    {
      id: PERMISSION_GROUPS.FINANCES,
      subgroups: [
        {
          id: PERMISSION_SUBGROUPS.INVOICES,
          permissions: [
            PERMISSIONS.CAN_VIEW_INVOICES,
            PERMISSIONS.CAN_PROCESS_INVOICES,
          ],
        },
        {
          id: PERMISSION_SUBGROUPS.SERVICE_ORDERS,
          permissions: [
            PERMISSIONS.CAN_REVIEW_ALL_WORKSHEETS,
            PERMISSIONS.CAN_REVIEW_WORKSHEETS,
            PERMISSIONS.CAN_REVIEW_ALL_EXPENSES,
            PERMISSIONS.CAN_REVIEW_EXPENSES,
            PERMISSIONS.CAN_REVIEW_ALL_PROFORMA_INVOICES,
          ],
        },
      ],
    },
    {
      id: PERMISSION_GROUPS.SETTINGS,
      subgroups: [
        {
          id: PERMISSION_SUBGROUPS.GENERAL,
          permissions: [PERMISSIONS.CAN_VIEW_ORGANIZATION_SETUP],
        },
        {
          id: PERMISSION_SUBGROUPS.FINANCES,
          permissions: [PERMISSIONS.CAN_MANAGE_PAYMENTS_SETTINGS],
        },
      ],
    },
  ],
  [SYSTEM_ROLES.SYSTEM_ADMIN]: [],
};

export const PERMISSION_GROUPS_CONFIGURATION = [
  {
    id: PERMISSION_GROUPS.TEAM,
    subgroups: [
      {
        id: PERMISSION_SUBGROUPS.PROVIDERS,
        permissions: [
          PERMISSIONS.CAN_INVITE_PROVIDERS,
          PERMISSIONS.CAN_REVIEW_ONBOARDING_FORMS,
          PERMISSIONS.CAN_DELETE_PROVIDER_RATES,
          PERMISSIONS.CAN_CREATE_EDIT_PROVIDER_RATES,
          PERMISSIONS.CAN_SET_PROVIDER_DEFAULT_RATE,
          PERMISSIONS.CAN_MANAGE_INVOICE_CAPS,
          PERMISSIONS.CAN_VIEW_PROVIDER_TAX_INFORMATION,
          PERMISSIONS.CAN_BULK_IMPORT_USERS,
          PERMISSIONS.CAN_DEACTIVATE_PROVIDERS,
          PERMISSIONS.CAN_REACTIVATE_PROVIDERS,
          PERMISSIONS.CAN_VIEW_PROVIDER_PAYMENT_DETAILS,
        ],
      },
      {
        id: PERMISSION_SUBGROUPS.MANAGERS,
        permissions: [
          PERMISSIONS.CAN_INVITE_MANAGERS,
          PERMISSIONS.CAN_DEACTIVATE_MANAGERS,
          PERMISSIONS.CAN_REACTIVATE_MANAGERS,
        ],
      },
    ],
  },
  {
    id: PERMISSION_GROUPS.PROJECTS_AND_TASKS,
    subgroups: [
      {
        id: PERMISSION_SUBGROUPS.PROJECTS,
        permissions: [
          PERMISSIONS.CAN_CREATE_PROJECTS,
          PERMISSIONS.CAN_MANAGE_ALL_PROJECTS,
        ],
      },
      {
        id: PERMISSION_SUBGROUPS.TASKS,
        permissions: [
          PERMISSIONS.CAN_CREATE_TASKS,
          PERMISSIONS.CAN_SUGGEST_TASK_RATES,
        ],
      },
    ],
  },
  {
    id: PERMISSION_GROUPS.REPORTS,
    subgroups: [
      { id: PERMISSION_SUBGROUPS.REPORTS, permissions: [PERMISSIONS.CAN_ACCESS_REPORTS] },
    ],
  },
  {
    id: PERMISSION_GROUPS.FINANCES,
    subgroups: [
      {
        id: PERMISSION_SUBGROUPS.SERVICE_ORDERS,
        permissions: [
          PERMISSIONS.CAN_REVIEW_WORKSHEETS,
          PERMISSIONS.CAN_REVIEW_ALL_WORKSHEETS,
          PERMISSIONS.CAN_REVIEW_EXPENSES,
          PERMISSIONS.CAN_REVIEW_ALL_EXPENSES,
          PERMISSIONS.CAN_REVIEW_ALL_PROFORMA_INVOICES,
        ],
      },
      {
        id: PERMISSION_SUBGROUPS.INVOICES,
        permissions: [
          PERMISSIONS.CAN_VIEW_INVOICES,
          PERMISSIONS.CAN_PROCESS_INVOICES,
        ],
      },
    ],
  },
  {
    id: PERMISSION_GROUPS.SETTINGS,
    subgroups: [
      {
        id: PERMISSION_SUBGROUPS.GENERAL,
        permissions: [
          PERMISSIONS.CAN_MANAGE_ORGANIZATION_SETTINGS,
          PERMISSIONS.CAN_VIEW_ORGANIZATION_SETUP,
        ],
      },
      {
        id: PERMISSION_SUBGROUPS.ROLES_AND_PERMISSIONS,
        permissions: [
          PERMISSIONS.CAN_MANAGE_ROLES,
        ],
      },
      {
        id: PERMISSION_SUBGROUPS.FINANCES,
        permissions: [
          PERMISSIONS.CAN_MANAGE_PAYMENTS_SETTINGS,
        ],
      },
    ],
  },
  {
    id: PERMISSION_GROUPS.LEGAL_AND_TAXES,
    subgroups: [
      {
        id: PERMISSION_SUBGROUPS['1099_FILINGS'],
        permissions: [
          PERMISSIONS.CAN_VIEW_1099_CONTRACTORS,
          PERMISSIONS.CAN_FILE_1099_REPORTS,
          PERMISSIONS.CAN_REVIEW_IRS_TAXPAYER_INFORMATION_FORMS,
        ],
      },
      {
        id: PERMISSION_SUBGROUPS.CLASSIFICATION_QUESTIONNAIRE,
        permissions: [
          PERMISSIONS.CAN_VIEW_CLASSIFICATION_QUESTIONNAIRE_SUBMISSIONS,
        ],
      },
    ],
  },
];

export const ROLES_TABS = {
  ROLES: 'roles',
  PERMISSIONS: 'permissions',
};
