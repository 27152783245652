import { camelCase, fromPairs, toPairs, upperFirst } from 'lodash';

import { ICON } from 'core/assets/js/constants';

export const MAX_SUMMARIES = 10;

export const ACTIVITY_TYPE = {
  PING: 1,
  MESSAGE_DIRECT: 2,
  MESSAGE_BROADCAST: 3,

  PROJECT_CREATED: 10,
  PROJECT_DETAILS_UPDATED: 11,
  PROJECT_STARTED: 12,
  PROJECT_COMPLETED: 13,
  PROJECT_ARCHIVED: 14,
  PROJECT_REOPENED: 15,

  PROJECT_MEMBER_INVITED: 20,
  PROJECT_MEMBER_JOINED: 21,

  BILLABLE_RAISED: 30,
  BILLABLE_UPDATED: 31,
  BILLABLE_CANCELLED: 32,
  BILLABLE_APPROVED: 33,
  BILLABLE_REJECTED: 34,
  BILLABLE_CONFIRMED: 35,
  BILLABLE_AMENDMENT_REQUESTED: 36,
  BILLABLE_RESUBMITTED: 37,

  PURCHASE_ORDER_RAISED: 40,
  PURCHASE_ORDER_APPROVED: 41,
  PURCHASE_ORDER_REJECTED: 42,

  TASK_CREATED: 50,
  TASK_UPDATED: 51,
  TASK_ARCHIVED: 52,
  TASK_PROGRESS_UPDATED: 53,
  TASK_COMPLETION_REQUESTED: 54,
  TASK_COMPLETION_ACCEPTED: 55,
  TASK_COMPLETION_REJECTED: 56,
  TASK_COMPLETION_CANCELLED: 57,
  TASK_COMPLETED: 58,
  TASK_ASSIGNMENT_REOPENED: 59,
  TASK_ASSIGNMENT_REVIEWED: 60,
  TASK_STARTED: 61,
  TASK_STOPPED: 62,
  TASK_UNARCHIVED: 63,
  TASK_OWNERSHIP_TRANSFERRED: 64,
  TASK_APPROVED: 65,
  TASK_REOPENED: 66,

  TASK_ASSIGNMENT_CREATED: 70,
  TASK_ASSIGNMENT_ACCEPTED: 71,
  TASK_ASSIGNMENT_REJECTED: 72,
  TASK_ASSIGNMENT_CANCELLED: 73,
  TASK_ASSIGNMENT_REMOVED: 74,
  TASK_RATE_AMENDMENT_REQUESTED: 75,
  TASK_RATE_AMENDMENT_ACCEPTED: 76,
  TASK_RATE_AMENDMENT_REJECTED: 77,
  TASK_RATE_AMENDMENT_CANCELLED: 78,
  TASK_ASSIGNMENT_INVITATION_REOPENED: 79,
  TASK_ASSIGNMENT_LEFT: 80,
  TASK_ASSIGNMENT_COMPLETED: 81,
  TASK_ASSIGNMENT_RATE_CHANGED: 82,

  TASK_ITEM_MARKED_COMPLETE: 90,
  TASK_ITEM_MARKED_INCOMPLETE: 91,
  PROVIDER_CHANGED_TO_MANAGER: 92,
  MANAGER_CHANGED_TO_PROVIDER: 93,
  CONTRACTOR_CHANGED_TO_EMPLOYEE: 94,
  EMPLOYEE_CHANGED_TO_CONTRACTOR: 95,
  ORGANIZATION_OWNER_CHANGED: 96,
  SUBSCRIPTION_CREATED: 97,
  SUBSCRIPTION_UPDATED: 98,
  SUBSCRIPTION_TRANSACTION_RECORDED: 99,
  SUBSCRIPTION_SYNCD: 100,
  INVOICE_GENERATED: 101,
  ORGANIZATION_FEATURE_CHANGED: 102,
  ORG_PRICING_CHANGED: 103,
  DEACTIVATED_IN_ORG: 104,
  REACTIVATED_IN_ORG: 105,
  ORGANIZATION_CREATED: 106,
  ORGANIZATION_DEACTIVATED: 107,
  PRODUCT_CHANGED: 108,
  ORGANIZATION_USER_CREATED: 109,

  INVOICE_REBUILT: 110,
  INVOICE_UPDATE_STATIC_DATA: 111,
  INVOICING_SEND_NOTIFICATION: 113,
  INVOICE_MARK_AS_VOID: 114,
  INVOICE_MARK_AS_CANCELLED: 115,

  TRANSACTION_CREATE_TRANSACTION: 116,
  TRANSACTION_CANCEL: 117,
  TRANSACTION_CANCEL_MANUAL_TRANSACTION: 118,
  TRANSACTION_REFUND_MANUAL_TRANSACTION: 119,
  TRANSACTION_RECREATE_TRANSACTION: 120,

  INVOICE_MARK_AS_RAISED: 121,
  INVOICE_MARK_AS_BAD_DEBT: 122,

  USER_DEACTIVATED: 123,
  USER_REACTIVATED: 124,

  ADMIN_LOGGED_IN_AS: 125,

  INVOICING_ENTITY_UPDATED: 126,
  INVOICING_ENTITY_CREATED: 127,

  DELETED_TRANSACTION: 129,

  DEPLOYMENTS_STATUS_UPDATED: 130,

  AOR_MANUAL_ENABLE: 132,
  AOR_MANUAL_DISABLE: 133,

  TRANSFER_ALLOCATED: 134,
  TRANSFER_IGNORED: 136,
  TRANSFER_IGNORING_STOPPED: 137,

  IMPORT_CANCELLED: 140,

  CHANGED_PROVIDER_ONBOARDING_FORM: 141,

  AUDIT_TRAIL_INVOICE_CREATED: 400,
  AUDIT_TRAIL_INVOICE_PUBLISHED: 401,
  AUDIT_TRAIL_INVOICE_STATUS_CHANGED: 402,
  AUDIT_TRAIL_INVOICE_RAISED_STATUS_CHANGED: 403,
  AUDIT_TRAIL_INVOICE_NUMBER_CHANGED: 404,
};

export const NOTIFICATION_TYPES = {
  '1099FilingComplete': '1099FilingComplete',
  '1099FilingError': '1099FilingError',
  '1099FilingsDownloadAll': '1099FilingsDownloadAll',
  AccountEmailVerify: 'AccountEmailVerify',
  AccountPasswordChanged: 'AccountPasswordChanged',
  AccountPasswordCreate: 'AccountPasswordCreate',
  AccountPasswordReset: 'AccountPasswordReset',
  AccountTrialExpired: 'AccountTrialExpired',
  AorContractSignedByProvider: 'AorContractSignedByProvider',
  AorContractCounterSigned: 'AorContractCounterSigned',
  AorContractRejected: 'AorContractRejected',
  BankAccountReviewRequest: 'BankAccountReviewRequest',
  BillableAmendmentRequested: 'BillableAmendmentRequested',
  ChatCreation: 'ChatCreation',
  DocumentSignatureRequestsComplete: 'DocumentSignatureRequestsComplete',
  DocumentSignatureRequired: 'DocumentSignatureRequired',
  UnreadMessages: 'UnreadMessages',
  ErrorReport: 'ErrorReport',
  ESignCountersignPending: 'ESignCountersignPending',
  FinanceBudgetAllocated: 'FinanceBudgetAllocated',
  FinanceBudgetDeallocated: 'FinanceBudgetDeallocated',
  FinanceDepartmentEmailSet: 'FinanceDepartmentEmailSet',
  FinanceInvoiceOutboundRebuilt: 'FinanceInvoiceOutboundRebuilt',
  FinanceInvoicesDirectCreated: 'FinanceInvoicesDirectCreated',
  FinanceInvoicesBillPaymentsDirectCreated: 'FinanceInvoicesBillPaymentsDirectCreated',
  FinanceInvoicesDownload: 'FinanceInvoicesDownload',
  FinanceInvoicesInboundCreated: 'FinanceInvoicesInboundCreated',
  FinanceInvoiceOutboundCreated: 'FinanceInvoiceOutboundCreated',
  FinanceProviderDirectInvoicePaid: 'FinanceProviderDirectInvoicePaid',
  FinanceProviderInboundInvoicePaid: 'FinanceProviderInboundInvoicePaid',
  FinanceProviderInboundInvoicesPaid: 'FinanceProviderInboundInvoicesPaid',
  FinanceProviderInvoiceCreated: 'FinanceProviderInvoiceCreated',
  FinancialControllerAppointed: 'FinancialControllerAppointed',
  FinancialControllerDismissed: 'FinancialControllerDismissed',
  ImportComplete: 'ImportComplete',
  IntegrationActive: 'IntegrationActive',
  IntegrationSyncError: 'IntegrationSyncError',
  InviteUserToOrganizationError: 'InviteUserToOrganizationError',
  IdentityCheckDocumentationRequired: 'IdentityCheckDocumentationRequired',
  IdentityCheckSuccessful: 'IdentityCheckSuccessful',
  NewTaxInformationDocumentToReview: 'NewTaxInformationDocumentToReview',
  OnboardingInterviewAmend: 'OnboardingInterviewAmend',
  OnboardingInterviewAmendForManager: 'OnboardingInterviewAmendForManager',
  OnboardingInterviewSubmitted: 'OnboardingInterviewSubmitted',
  OnboardingInvitationAccepted: 'OnboardingInvitationAccepted',
  OnboardingInvitationCancelled: 'OnboardingInvitationCancelled',
  OnboardingInvitationRejected: 'OnboardingInvitationRejected',
  OnboardingInvitationReminder: 'OnboardingInvitationReminder',
  OnboardingInviteManager: 'OnboardingInviteManager',
  OnboardingInviteProvider: 'OnboardingInviteProvider',
  OnboardingInviteProviderPPH: 'OnboardingInviteProviderPPH',
  OnboardingProviderApproved: 'OnboardingProviderApproved',
  OnboardingProviderRejected: 'OnboardingProviderRejected',
  OnboardingReminderFirst: 'OnboardingReminderFirst',
  OnboardingReminderSecond: 'OnboardingReminderSecond',
  OnboardingReminderThird: 'OnboardingReminderThird',
  OnboardingReminderInvitingManager: 'OnboardingReminderInvitingManager',
  OnboardingSubmissionFormChanged: 'OnboardingSubmissionFormChanged',
  OnboardingSubmissionFormReset: 'OnboardingSubmissionFormReset',
  OpportunityInvitation: 'OpportunityInvitation',
  ProFormaInvoiceApproved: 'ProFormaInvoiceApproved',
  ProFormaInvoiceRaised: 'ProFormaInvoiceRaised',
  ProFormaInvoiceRejected: 'ProFormaInvoiceRejected',
  ProFormaInvoiceUpdated: 'ProFormaInvoiceUpdated',
  ProjectApplicationAccepted: 'ProjectApplicationAccepted',
  ProjectApplicationCancelled: 'ProjectApplicationCancelled',
  ProjectApplicationCreated: 'ProjectApplicationCreated',
  ProjectApplicationRejected: 'ProjectApplicationRejected',
  ProjectArchived: 'ProjectArchived',
  ProjectAssignedAsPaymentsReviewer: 'ProjectAssignedAsPaymentsReviewer',
  ProjectBudgetApproved: 'ProjectBudgetApproved',
  ProjectBudgetRejected: 'ProjectBudgetRejected',
  ProjectBudgetRequested: 'ProjectBudgetRequested',
  ProjectCompleted: 'ProjectCompleted',
  ProjectCompletedWithFunds: 'ProjectCompletedWithFunds',
  ProjectExpenseApproved: 'ProjectExpenseApproved',
  ProjectExpenseClaimed: 'ProjectExpenseClaimed',
  ProjectExpenseConfirmed: 'ProjectExpenseConfirmed',
  ProjectExpenseRejected: 'ProjectExpenseRejected',
  ProjectExpenseUpdated: 'ProjectExpenseUpdated',
  ProjectFeedbackLeft: 'ProjectFeedbackLeft',
  ProjectInvitationAccepted: 'ProjectInvitationAccepted',
  ProjectInvitationCancelled: 'ProjectInvitationCancelled',
  ProjectInvitationRejected: 'ProjectInvitationRejected',
  ProjectInviteManager: 'ProjectInviteManager',
  ProjectInviteProvider: 'ProjectInviteProvider',
  ProjectMemberLeft: 'ProjectMemberLeft',
  ProjectOutOfFunds: 'ProjectOutOfFunds',
  ProjectStarted: 'ProjectStarted',
  ProjectTaskAccepted: 'ProjectTaskAccepted',
  ProjectTaskArchived: 'ProjectTaskArchived',
  ProjectTaskAssigned: 'ProjectTaskAssigned',
  ProjectTaskAssignmentCompleted: 'ProjectTaskAssignmentCompleted',
  ProjectTaskAssignmentReopened: 'ProjectTaskAssignmentReopened',
  ProjectTaskAssignmentReviewed: 'ProjectTaskAssignmentReviewed',
  ProjectTaskCompleted: 'ProjectTaskCompleted',
  ProjectTaskCompletedWatcher: 'ProjectTaskCompletedWatcher',
  ProjectTaskManagerDeadlineReminder: 'ProjectTaskManagerDeadlineReminder',
  ProjectTaskProviderDeadlineReminder: 'ProjectTaskProviderDeadlineReminder',
  ProjectTaskRejected: 'ProjectTaskRejected',
  ProjectTaskStopped: 'ProjectTaskStopped',
  ProjectTaskUnarchived: 'ProjectTaskUnarchived',
  ProjectUserRemoved: 'ProjectUserRemoved',
  ProjectWorksheetApproved: 'ProjectWorksheetApproved',
  ProjectWorksheetConfirmed: 'ProjectWorksheetConfirmed',
  ProjectWorksheetRaised: 'ProjectWorksheetRaised',
  ProjectWorksheetRejected: 'ProjectWorksheetRejected',
  ProjectWorksheetReminder: 'ProjectWorksheetReminder',
  ProjectWorksheetUpdated: 'ProjectWorksheetUpdated',
  RateAdjustmentApproved: 'RateAdjustmentApproved',
  RateAdjustmentRejected: 'RateAdjustmentRejected',
  RateAdjustmentRequested: 'RateAdjustmentRequested',
  RateApproved: 'RateApproved',
  RateCreated: 'RateCreated',
  RateRejected: 'RateRejected',
  RateSet: 'RateSet',
  ServiceOrderCutoffReminder: 'ServiceOrderCutoffReminder',
  SubscriptionExpirationReminder: 'SubscriptionExpirationReminder',
  TaskAssignmentCancelled: 'TaskAssignmentCancelled',
  TaskAssignmentLeft: 'TaskAssignmentLeft',
  TaskAssignmentRemoved: 'TaskAssignmentRemoved',
  TaskCompletionAccepted: 'TaskCompletionAccepted',
  TaskCompletionRejected: 'TaskCompletionRejected',
  TaskCompletionRequested: 'TaskCompletionRequested',
  TaskDiscussionBoardMessage: 'TaskDiscussionBoardMessage',
  TaskOwnershipTransferred: 'TaskOwnershipTransferred',
  TaskRateApprovedByManager: 'TaskRateApprovedByManager',
  TaskRateApprovedByProvider: 'TaskRateApprovedByProvider',
  TaskRateRejectedByManager: 'TaskRateRejectedByManager',
  TaskRateRejectedByProvider: 'TaskRateRejectedByProvider',
  TaskRateSuggestedByManager: 'TaskRateSuggestedByManager',
  TaskRateSuggestedByProvider: 'TaskRateSuggestedByProvider',
  TaskReopened: 'TaskReopened',
  TaxInformationDocumentRejected: 'TaxInformationDocumentRejected',
  TimeTrackerRaiseWorksheetsComplete: 'TimeTrackerRaiseWorksheetsComplete',
  TimeTrackerRaiseWorksheetsError: 'TimeTrackerRaiseWorksheetsError',
  TrialPeriodExpirationReminder: 'TrialPeriodExpirationReminder',
  UserDemoRequest: 'UserDemoRequest',
  USTaxIdentificationDetailsInvalid: 'USTaxIdentificationDetailsInvalid',
  USTaxPayerMissingPostalCode: 'USTaxPayerMissingPostalCode',
  WeeklyServiceOrdersPendingSummary: 'WeeklyServiceOrdersPendingSummary',
};

export const NOTIFICATION_TYPES_VALUES = Object.values(NOTIFICATION_TYPES);

// These notifications are not received by individual users
export const NOTIFICATION_TYPES_BACK_END = [
  NOTIFICATION_TYPES.ErrorReport,
  NOTIFICATION_TYPES.FinanceInvoicesDownload,
  NOTIFICATION_TYPES.UserDemoRequest,
];

// Notifications where saveOnSite=false
export const NOTIFICATION_TYPES_NOT_SAVED = [
  NOTIFICATION_TYPES.AccountEmailVerify,
  NOTIFICATION_TYPES.AccountPasswordChanged,
  NOTIFICATION_TYPES.AccountPasswordCreate,
  NOTIFICATION_TYPES.AccountPasswordReset,
  NOTIFICATION_TYPES.BankAccountReviewRequest,
  NOTIFICATION_TYPES.ErrorReport,
  NOTIFICATION_TYPES.ESignCountersignPending,
  NOTIFICATION_TYPES.FinanceDepartmentEmailSet,
  NOTIFICATION_TYPES.FinanceInvoicesInboundCreated,
];

export const NOTIFICATION_TYPE_LABELS = {
  [NOTIFICATION_TYPES.OnboardingInvitationCancelled]: 'Invitation cancelled',
  [NOTIFICATION_TYPES.OnboardingInvitationReminder]: 'Invitation reminder',
  [NOTIFICATION_TYPES.OnboardingInviteProvider]: 'Provider invitation',
  [NOTIFICATION_TYPES.OnboardingProviderApproved]: 'Onboarding approved',
  [NOTIFICATION_TYPES.OnboardingProviderRejected]: 'Onboarding rejected',
  [NOTIFICATION_TYPES.OnboardingReminderFirst]: 'Complete onboarding form reminder - First',
  [NOTIFICATION_TYPES.OnboardingReminderSecond]: 'Complete onboarding form reminder - Second',
  [NOTIFICATION_TYPES.OnboardingReminderThird]: 'Complete onboarding form reminder - Third',
  [NOTIFICATION_TYPES.ProjectInviteProvider]: 'Project provider invitation',
  [NOTIFICATION_TYPES.ProjectTaskAssigned]: 'Project task assigned',
  [NOTIFICATION_TYPES.ProjectTaskCompleted]: 'Project task completed (assignee)',
  [NOTIFICATION_TYPES.ProjectTaskCompletedWatcher]: 'Project task completed (watcher)',
  // TODO remaining notifications
};

export const NOTIFICATION_TYPE_DESCRIPTIONS = {
  [NOTIFICATION_TYPES.OnboardingInvitationCancelled]: (
    "A manager has cancelled a user's invitation to the organization"
  ),
  [NOTIFICATION_TYPES.OnboardingInvitationReminder]: (
    'A reminder is sent after 7 days and then 14 days, to an invited provider who has not accepted or rejected their invitation'
  ),
  [NOTIFICATION_TYPES.OnboardingInviteProvider]: (
    'A new provider is invited to join the organization'
  ),
  [NOTIFICATION_TYPES.OnboardingProviderApproved]: (
    'An invited user’s onboarding has been approved.'
  ),
  [NOTIFICATION_TYPES.OnboardingProviderRejected]: (
    'An invited user’s onboarding has been rejected'
  ),
  [NOTIFICATION_TYPES.OnboardingReminderFirst]: (
    'A reminder is sent to a provider to complete their onboarding form, one day after they '
    + 'joined the organization'
  ),
  [NOTIFICATION_TYPES.OnboardingReminderSecond]: (
    'A second reminder is sent to a provider to complete their onboarding form, two days after '
      + 'they joined the organization'
  ),
  [NOTIFICATION_TYPES.OnboardingReminderThird]: (
    'A third reminder is sent to a provider to complete their onboarding form, three days after '
      + 'they joined the organization'
  ),
  [NOTIFICATION_TYPES.ProjectInviteProvider]: 'Sent when a provider is invited to join a project.',
  [NOTIFICATION_TYPES.ProjectTaskAssigned]: (
    'Sent when a provider is invited to collaborate on a project task.'
  ),
  [NOTIFICATION_TYPES.ProjectTaskCompleted]: 'Sent to task assignees, when a task is completed',
  [NOTIFICATION_TYPES.ProjectTaskCompletedWatcher]: (
    'Sent to task watchers, when a task is completed'
  ),
  // TODO remaining notifications
};

export const NOTIFICATION_GROUPS = {
  ACCOUNT: 1,
  FINANCE: 2,
  ONBOARDING_FORMS: 3,
  ORGANIZATION_INVITATIONS: 4,
  PAYMENT_REQUESTS: 5,
  PROJECT_ASSIGNMENTS: 6,
  PROJECT_PROGRESS: 7,
  RATES: 8,
  TASK_ASSIGNMENTS: 9,
  TASK_PROGRESS: 10,
  ORGANIZATION: 11,
  INTEGRATION: 12,
  MESSAGING: 13,
  TAX: 14,
  DOCUMENTS: 15,
};

export const NOTIFICATION_GROUP_LABELS = {
  [NOTIFICATION_GROUPS.ACCOUNT]: 'Account',
  [NOTIFICATION_GROUPS.FINANCE]: 'Finance',
  [NOTIFICATION_GROUPS.ONBOARDING_FORMS]: 'Onboarding forms',
  [NOTIFICATION_GROUPS.ORGANIZATION_INVITATIONS]: 'Organization invitations',
  [NOTIFICATION_GROUPS.PAYMENT_REQUESTS]: 'Payment requests',
  [NOTIFICATION_GROUPS.PROJECT_ASSIGNMENTS]: 'Project assignments',
  [NOTIFICATION_GROUPS.PROJECT_PROGRESS]: 'Project progress',
  [NOTIFICATION_GROUPS.RATES]: 'Rates',
  [NOTIFICATION_GROUPS.TASK_ASSIGNMENTS]: 'Task assignments',
  [NOTIFICATION_GROUPS.TASK_PROGRESS]: 'Task progress',
  [NOTIFICATION_GROUPS.ORGANIZATION]: 'Organization',
  [NOTIFICATION_GROUPS.INTEGRATION]: 'Integrations',
  [NOTIFICATION_GROUPS.MESSAGING]: 'Messaging',
  [NOTIFICATION_GROUPS.TAX]: 'Tax',
  [NOTIFICATION_GROUPS.DOCUMENTS]: 'Contract templates',
};

export const NOTIFICATION_GROUP_ICONS = {
  [NOTIFICATION_GROUPS.ONBOARDING_FORMS]: ICON.CLIPBOARD_LIST_CHECK_DUOTONE,
  [NOTIFICATION_GROUPS.ORGANIZATION_INVITATIONS]: ICON.USERS_MEDICAL_DUOTONE,
  [NOTIFICATION_GROUPS.PROJECT_ASSIGNMENTS]: ICON.BAR_PROGRESS_DUOTONE,
  [NOTIFICATION_GROUPS.TASK_ASSIGNMENTS]: ICON.LIST_CHECK_DUOTONE,
  [NOTIFICATION_GROUPS.TASK_PROGRESS]: ICON.LIST_CHECK_DUOTONE,
  // TODO remaining groups
};

export const NOTIFICATION_TYPES_BY_GROUP = {
  [NOTIFICATION_GROUPS.ACCOUNT]: [
    NOTIFICATION_TYPES.AccountPasswordReset,
    NOTIFICATION_TYPES.AccountPasswordChanged,
    NOTIFICATION_TYPES.AccountEmailVerify,
    NOTIFICATION_TYPES.AccountTrialExpired,
    NOTIFICATION_TYPES.AorContractCounterSigned,
    NOTIFICATION_TYPES.AorContractSignedByProvider,
    NOTIFICATION_TYPES.AorContractRejected,
    NOTIFICATION_TYPES.BankAccountReviewRequest,
    NOTIFICATION_TYPES.AccountPasswordCreate,
  ],
  [NOTIFICATION_GROUPS.DOCUMENTS]: [
    NOTIFICATION_TYPES.DocumentSignatureRequired,
    NOTIFICATION_TYPES.DocumentSignatureRequestsComplete,
  ],
  [NOTIFICATION_GROUPS.FINANCE]: [
    NOTIFICATION_TYPES.FinancialControllerAppointed,
    NOTIFICATION_TYPES.FinancialControllerDismissed,
    NOTIFICATION_TYPES.FinanceBudgetAllocated,
    NOTIFICATION_TYPES.FinanceBudgetDeallocated,
    NOTIFICATION_TYPES.ProjectBudgetRequested,
    NOTIFICATION_TYPES.ProjectBudgetApproved,
    NOTIFICATION_TYPES.ProjectBudgetRejected,
    NOTIFICATION_TYPES.FinanceInvoicesDirectCreated,
    NOTIFICATION_TYPES.FinanceInvoicesBillPaymentsDirectCreated,
    NOTIFICATION_TYPES.FinanceInvoicesInboundCreated,
    NOTIFICATION_TYPES.FinanceInvoiceOutboundCreated,
    NOTIFICATION_TYPES.FinanceInvoiceOutboundRebuilt,
    NOTIFICATION_TYPES.FinanceProviderInvoiceCreated,
    NOTIFICATION_TYPES.FinanceProviderDirectInvoicePaid,
    NOTIFICATION_TYPES.FinanceProviderInboundInvoicePaid,
    NOTIFICATION_TYPES.FinanceProviderInboundInvoicesPaid,
    NOTIFICATION_TYPES.FinanceDepartmentEmailSet,
    NOTIFICATION_TYPES.ProFormaInvoiceRaised,
    NOTIFICATION_TYPES.ProFormaInvoiceUpdated,
    NOTIFICATION_TYPES.ProFormaInvoiceApproved,
    NOTIFICATION_TYPES.ProFormaInvoiceRejected,
    NOTIFICATION_TYPES.ServiceOrderCutoffReminder,
    NOTIFICATION_TYPES.TimeTrackerRaiseWorksheetsComplete,
    NOTIFICATION_TYPES.TimeTrackerRaiseWorksheetsError,
  ],
  [NOTIFICATION_GROUPS.ONBOARDING_FORMS]: [
    NOTIFICATION_TYPES.IdentityCheckDocumentationRequired,
    NOTIFICATION_TYPES.IdentityCheckSuccessful,
    NOTIFICATION_TYPES.OnboardingInterviewSubmitted,
    NOTIFICATION_TYPES.OnboardingInterviewAmend,
    NOTIFICATION_TYPES.OnboardingProviderApproved,
    NOTIFICATION_TYPES.OnboardingProviderRejected,
    NOTIFICATION_TYPES.OnboardingInterviewAmendForManager,
    NOTIFICATION_TYPES.OnboardingReminderFirst,
    NOTIFICATION_TYPES.OnboardingReminderSecond,
    NOTIFICATION_TYPES.OnboardingReminderThird,
    NOTIFICATION_TYPES.OnboardingReminderInvitingManager,
    NOTIFICATION_TYPES.OnboardingSubmissionFormChanged,
    NOTIFICATION_TYPES.OnboardingSubmissionFormReset,
  ],
  [NOTIFICATION_GROUPS.ORGANIZATION_INVITATIONS]: [
    NOTIFICATION_TYPES.InviteUserToOrganizationError,
    NOTIFICATION_TYPES.OnboardingInvitationReminder,
    NOTIFICATION_TYPES.OnboardingInviteManager,
    NOTIFICATION_TYPES.OnboardingInviteProvider,
    NOTIFICATION_TYPES.OnboardingInviteProviderPPH,
    NOTIFICATION_TYPES.OnboardingInvitationAccepted,
    NOTIFICATION_TYPES.OnboardingInvitationRejected,
    NOTIFICATION_TYPES.OnboardingInvitationCancelled,
  ],
  [NOTIFICATION_GROUPS.PAYMENT_REQUESTS]: [
    NOTIFICATION_TYPES.ProjectWorksheetReminder,
    NOTIFICATION_TYPES.ProjectWorksheetRaised,
    NOTIFICATION_TYPES.ProjectWorksheetConfirmed,
    NOTIFICATION_TYPES.ProjectWorksheetRejected,
    NOTIFICATION_TYPES.ProjectWorksheetApproved,
    NOTIFICATION_TYPES.BillableAmendmentRequested,
    NOTIFICATION_TYPES.ProjectWorksheetUpdated,
    NOTIFICATION_TYPES.ProjectExpenseClaimed,
    NOTIFICATION_TYPES.ProjectExpenseConfirmed,
    NOTIFICATION_TYPES.ProjectExpenseRejected,
    NOTIFICATION_TYPES.ProjectExpenseApproved,
    NOTIFICATION_TYPES.ProjectExpenseUpdated,
    NOTIFICATION_TYPES.WeeklyServiceOrdersPendingSummary,
  ],
  [NOTIFICATION_GROUPS.PROJECT_ASSIGNMENTS]: [
    NOTIFICATION_TYPES.ProjectInviteProvider,
    NOTIFICATION_TYPES.ProjectInviteManager,
    NOTIFICATION_TYPES.ProjectInvitationAccepted,
    NOTIFICATION_TYPES.ProjectInvitationRejected,
    NOTIFICATION_TYPES.ProjectInvitationCancelled,
    NOTIFICATION_TYPES.ProjectApplicationCreated,
    NOTIFICATION_TYPES.ProjectApplicationCancelled,
    NOTIFICATION_TYPES.ProjectApplicationAccepted,
    NOTIFICATION_TYPES.ProjectApplicationRejected,
    NOTIFICATION_TYPES.OpportunityInvitation,
  ],
  [NOTIFICATION_GROUPS.PROJECT_PROGRESS]: [
    NOTIFICATION_TYPES.ProjectStarted,
    NOTIFICATION_TYPES.ProjectCompleted,
    NOTIFICATION_TYPES.ProjectCompletedWithFunds,
    NOTIFICATION_TYPES.ProjectOutOfFunds,
    NOTIFICATION_TYPES.ProjectFeedbackLeft,
    NOTIFICATION_TYPES.ProjectArchived,
    NOTIFICATION_TYPES.ProjectUserRemoved,
    NOTIFICATION_TYPES.ProjectMemberLeft,
  ],
  [NOTIFICATION_GROUPS.RATES]: [
    NOTIFICATION_TYPES.RateCreated,
    NOTIFICATION_TYPES.RateApproved,
    NOTIFICATION_TYPES.RateSet,
    NOTIFICATION_TYPES.RateRejected,
    NOTIFICATION_TYPES.RateAdjustmentRequested,
    NOTIFICATION_TYPES.RateAdjustmentApproved,
    NOTIFICATION_TYPES.RateAdjustmentRejected,
  ],
  [NOTIFICATION_GROUPS.TASK_ASSIGNMENTS]: [
    NOTIFICATION_TYPES.ProjectTaskAssigned,
    NOTIFICATION_TYPES.ProjectTaskAccepted,
    NOTIFICATION_TYPES.ProjectTaskRejected,
    NOTIFICATION_TYPES.TaskAssignmentCancelled,
    NOTIFICATION_TYPES.TaskAssignmentRemoved,
    NOTIFICATION_TYPES.ProjectTaskAssignmentCompleted,
    NOTIFICATION_TYPES.ProjectAssignedAsPaymentsReviewer,
    NOTIFICATION_TYPES.TaskAssignmentLeft,
    NOTIFICATION_TYPES.TaskRateSuggestedByProvider,
    NOTIFICATION_TYPES.TaskRateSuggestedByManager,
    NOTIFICATION_TYPES.TaskRateApprovedByProvider,
    NOTIFICATION_TYPES.TaskRateRejectedByProvider,
    NOTIFICATION_TYPES.TaskRateApprovedByManager,
    NOTIFICATION_TYPES.TaskRateRejectedByManager,
  ],
  [NOTIFICATION_GROUPS.TASK_PROGRESS]: [
    NOTIFICATION_TYPES.ProjectTaskStopped,
    NOTIFICATION_TYPES.TaskCompletionRequested,
    NOTIFICATION_TYPES.TaskCompletionAccepted,
    NOTIFICATION_TYPES.TaskCompletionRejected,
    NOTIFICATION_TYPES.ProjectTaskCompleted,
    NOTIFICATION_TYPES.ProjectTaskCompletedWatcher,
    NOTIFICATION_TYPES.ProjectTaskAssignmentReopened,
    NOTIFICATION_TYPES.ProjectTaskAssignmentReviewed,
    NOTIFICATION_TYPES.ProjectTaskProviderDeadlineReminder,
    NOTIFICATION_TYPES.ProjectTaskManagerDeadlineReminder,
    NOTIFICATION_TYPES.ProjectTaskArchived,
    NOTIFICATION_TYPES.ProjectTaskUnarchived,
    NOTIFICATION_TYPES.TaskOwnershipTransferred,
    NOTIFICATION_TYPES.TaskDiscussionBoardMessage,
    NOTIFICATION_TYPES.TaskReopened,
  ],
  [NOTIFICATION_GROUPS.ORGANIZATION]: [
    NOTIFICATION_TYPES.TrialPeriodExpirationReminder,
    NOTIFICATION_TYPES.SubscriptionExpirationReminder,
    NOTIFICATION_TYPES.ImportComplete,
    NOTIFICATION_TYPES.ChatCreation,
  ],
  [NOTIFICATION_GROUPS.INTEGRATION]: [
    NOTIFICATION_TYPES.IntegrationActive,
    NOTIFICATION_TYPES.IntegrationSyncError,
  ],
  [NOTIFICATION_GROUPS.MESSAGING]: [
    NOTIFICATION_TYPES.UnreadMessages,
  ],
  [NOTIFICATION_GROUPS.TAX]: [
    NOTIFICATION_TYPES.NewTaxInformationDocumentToReview,
    NOTIFICATION_TYPES.TaxInformationDocumentRejected,
    NOTIFICATION_TYPES['1099FilingComplete'],
    NOTIFICATION_TYPES['1099FilingError'],
    NOTIFICATION_TYPES.USTaxIdentificationDetailsInvalid,
    NOTIFICATION_TYPES.USTaxPayerMissingPostalCode,
    NOTIFICATION_TYPES['1099FilingsDownloadAll'],
  ],
};

export const PERMISSION_NOTIFICATIONS = {
  ENABLE_PAYMENTS_NOTIFICATIONS: NOTIFICATION_TYPES_BY_GROUP[NOTIFICATION_GROUPS.PAYMENT_REQUESTS],
};

export const ACTIVITY_DATE_FORMAT = 'DD MMM YYYY HH:mm';

export const ACTIVITY_TYPE_TO_NAME = fromPairs(toPairs(ACTIVITY_TYPE).map(([k, t]) => [
  t, upperFirst(camelCase(k)),
]));

export const NAME_TO_ACTIVITY_TYPE = fromPairs(toPairs(ACTIVITY_TYPE).map(([k, t]) => [
  upperFirst(camelCase(k)), t,
]));

export const CUSTOMIZABLE_NOTIFICATIONS = [
  NOTIFICATION_TYPES.OnboardingInvitationCancelled,
  NOTIFICATION_TYPES.OnboardingInvitationReminder,
  NOTIFICATION_TYPES.OnboardingInviteProvider,
  NOTIFICATION_TYPES.OnboardingProviderApproved,
  NOTIFICATION_TYPES.OnboardingProviderRejected,
  NOTIFICATION_TYPES.OnboardingReminderFirst,
  NOTIFICATION_TYPES.OnboardingReminderSecond,
  NOTIFICATION_TYPES.OnboardingReminderThird,
  NOTIFICATION_TYPES.ProjectInviteProvider,
  NOTIFICATION_TYPES.ProjectTaskAssigned,
  NOTIFICATION_TYPES.ProjectTaskCompleted,
  NOTIFICATION_TYPES.ProjectTaskCompletedWatcher,
];

// IMPORTANT these must match the fields specified in the notification
export const NOTIFICATION_TAGS = {
  [NOTIFICATION_TYPES.OnboardingInvitationCancelled]: [
    { description: 'The name of the manager who cancelled the invitation', tag: 'managerName' },
    {
      description: 'The message specified when cancelling the invitation',
      dontEscape: true,
      tag: 'message',
    },
    { description: "The organization's name", tag: 'orgName' },
  ],
  [NOTIFICATION_TYPES.OnboardingInvitationReminder]: [
    { description: "The organization's name", tag: 'orgName' },
    { description: 'The name of the manager who sent the invitation', tag: 'managerName' },
    { description: 'The name of the role the user has been invited as', tag: 'role' },
    { description: 'The URL for the user to accept the invitation', tag: 'acceptUrl' },
    { description: 'The URL for the user to reject the invitation', tag: 'rejectUrl' },
    { description: 'The URL for the user to review or accept the invitation', tag: 'targetUrl' },
  ],
  [NOTIFICATION_TYPES.OnboardingInviteProvider]: [
    { description: 'The URL for the user to accept the invitation', tag: 'acceptUrl' },
    { description: 'The name of the manager who sent the invitation', tag: 'managerName' },
    {
      description: 'The message specified when sending the invitation',
      dontEscape: true,
      tag: 'message',
    },
    { description: "The organization's name", tag: 'orgName' },
    { description: 'The URL for the user to reject the invitation', tag: 'rejectUrl' },
    { description: 'The name of the role the user has been invited as', tag: 'role' },
    { description: 'The URL for the user to review or accept the invitation', tag: 'targetUrl' },
  ],
  [NOTIFICATION_TYPES.OnboardingProviderApproved]: [
    { description: 'The name of the invited user', tag: 'greeting' },
    { description: "The organization's name", tag: 'orgName' },
  ],
  [NOTIFICATION_TYPES.OnboardingProviderRejected]: [
    { description: 'The name of the invited user', tag: 'greeting' },
    { description: 'The name of the manager who sent the invitation', tag: 'managerName' },
    { description: "The organization's name", tag: 'orgName' },
    { description: 'The name of the role the user has been invited as', tag: 'role' },
  ],
  [NOTIFICATION_TYPES.OnboardingReminderFirst]: [
    { description: 'The name of the invited user', tag: 'greeting' },
    { description: "The organization's name", tag: 'orgName' },
    { description: 'The URL for the user to review or accept the invitation', tag: 'targetUrl' },
  ],
  [NOTIFICATION_TYPES.OnboardingReminderSecond]: [
    { description: 'The name of the invited user', tag: 'greeting' },
    { description: "The organization's name", tag: 'orgName' },
    { description: 'The URL for the user to review or accept the invitation', tag: 'targetUrl' },
  ],
  [NOTIFICATION_TYPES.OnboardingReminderThird]: [
    { description: 'The name of the invited user', tag: 'greeting' },
    { description: "The organization's name", tag: 'orgName' },
    { description: 'The URL for the user to review or accept the invitation', tag: 'targetUrl' },
  ],
  [NOTIFICATION_TYPES.ProjectInviteProvider]: [
    { description: 'The first name of the provider invited', tag: 'greeting' },
    { description: 'The name of the manager who sent the invitation', tag: 'managerName' },
    { description: "The organization's name", tag: 'orgName' },
    { description: "The project's title", tag: 'projTitle' },
    { description: "The URL for the user's invitations page", tag: 'targetUrl' },
  ],
  [NOTIFICATION_TYPES.ProjectTaskAssigned]: [
    { description: 'The name of the invited user', tag: 'greeting' },
    { description: 'The name of the manager who sent the invitation', tag: 'managerName' },
    { description: 'The optional message sent with the invitation', tag: 'message' },
    { description: "The project's title", tag: 'projTitle' },
    { description: "The task assignment's rate", tag: 'rateFormatted' },
    { description: 'The URL to the task invitation', tag: 'targetUrl' },
    { description: "The task's title", tag: 'taskTitle' },
  ],
  [NOTIFICATION_TYPES.ProjectTaskCompleted]: [
    { description: 'The name of the user', tag: 'greeting' },
    { description: 'The name of the manager who completed the task', tag: 'managerName' },
    { description: 'The URL to view the task', tag: 'targetUrl' },
    { description: "The task's title", tag: 'taskTitle' },
  ],
  [NOTIFICATION_TYPES.ProjectTaskCompletedWatcher]: [
    { description: 'The name of the user', tag: 'greeting' },
    { description: 'The name of the manager who completed the task', tag: 'managerName' },
    { description: 'The URL to view the task', tag: 'targetUrl' },
    { description: "The task's title", tag: 'taskTitle' },
  ],
};
