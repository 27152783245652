/* globals FormData */
import React from 'react';
import PropTypes from 'prop-types';
import arrayMutators from 'final-form-arrays';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Form, Field } from 'react-final-form';

import UserAndGroupSearchField from 'core/assets/js/components/FinalFormFields/UserAndGroupSearchField.jsx';
import { customFieldSpec, customFieldTemplateSpec } from 'interviews/assets/js/lib/objectSpecs';
import LoadingComponent from 'core/assets/js/components/LoadingComponent.jsx';
import DatePickerField from 'core/assets/js/components/FinalFormFields/DatePickerField.jsx';
import InputNumberField from 'core/assets/js/components/FinalFormFields/InputNumberField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import TagsInputField from 'core/assets/js/components/FinalFormFields/TagsInputField.jsx';
import MoneyInputField from 'core/assets/js/components/FinalFormFields/MoneyInputField.jsx';
import FileUploaderDirectField from 'core/assets/js/components/FinalFormFields/FileUploaderDirectField.jsx';
import RateField from 'rates/assets/js/components/finalFormFields/RateField.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import SkillSelectField from 'core/assets/js/components/FinalFormFields/SkillSelectField.jsx';
import RadioField from 'core/assets/js/components/FinalFormFields/RadioField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import ProjectListSkeleton from 'core/assets/js/components/Skeleton/ProjectListSkeleton.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import CustomFieldsUpdater from 'interviews/assets/js/components/CustomFieldsUpdater.jsx';
import { getViewState } from 'core/assets/js/ducks/view';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import { createProjectDS, updateProjectDS } from 'projects/assets/js/data-services/form';
import { FIELD_ENTITY_TYPE, PATH_PREFIX } from 'interviews/assets/js/constants';
import { BS_STYLE, MAX_UPLOAD_FILES } from 'core/assets/js/constants';
import { projectListUrl, projectViewUrl } from 'projects/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { fetchActiveManagerBudgetDS } from 'people/assets/js/ducks/managers';
import {
  PROJECT_DOCUMENTS_ALLOWED_MIMETYPES,
  PROJECT_MEMBER_MANAGEMENT,
  PROJECT_MEMBER_MANAGEMENT_LABEL,
  PROJECT_MEMBER_MANAGEMENT_SUBLABEL,
  PROJECT_MEMBER_VIEW,
  PROJECT_MEMBER_VIEW_LABEL,
  PROJECT_MEMBER_VIEW_SUBLABEL,
  PROJECT_VISIBILITY,
  PROJECT_VISIBILITY_LABEL,
  PROJECT_VISIBILITY_SUBLABEL,
  RATE_GUIDANCE_TYPE,
} from 'projects/assets/js/constants';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { RATE_UNIT_FORMAT, RATE_UNIT_SELECT } from 'rates/assets/js/constants';

const FormRenderer = ({
  activeOrg,
  allowTemplateSelection,
  customFields,
  customFieldTemplates,
  embedded,
  enableBudgetAllocation,
  enableEditingMemberSettings,
  expandSettings,
  form,
  handleSubmit,
  history,
  initialValues,
  managerBudget,
  orgAlias,
  projectId,
  submitLabel,
  submitting,
}) => {
  const editingProject = !!initialValues.id;
  const submitBtnLabel = submitLabel || 'Create project';
  const isBudgetAllocEnabled = !isEmpty(managerBudget) && enableBudgetAllocation;
  const canChangeProjectVisibility = !editingProject;

  const projectVisibility = projectId
    ? initialValues.visibility // you cannot change an existing project's visibility
    : form.getState().values.visibility;
  let budgetSublabel = null;

  if (isBudgetAllocEnabled) {
    if (!parseFloat(managerBudget.availableBudget)) {
      budgetSublabel = (
        <React.Fragment>
          You don’t have any budget available, but you can proceed with the project
          creation without allocating a budget. You will be able to request budget once the
          project is created.
        </React.Fragment>
      );
    } else {
      budgetSublabel = (
        <React.Fragment>
          Your available budget is
          {' '}
          <NumberTpl
            prefix={managerBudget.currency}
            value={managerBudget.availableBudget}
            decimals={2}
          />
        </React.Fragment>
      );
    }
  }

  const visibilityOptions = Object.values(PROJECT_VISIBILITY).map(v => ({
    value: v,
    text: (
      <div>
        {PROJECT_VISIBILITY_LABEL[v]}
        <div className="discreet">
          {PROJECT_VISIBILITY_SUBLABEL[v]}
        </div>
      </div>
    ),
  }));

  const memberManagementOptions = Object.values(PROJECT_MEMBER_MANAGEMENT).map(v => ({
    value: v,
    text: (
      <div>
        {PROJECT_MEMBER_MANAGEMENT_LABEL[v]}
        <div className="discreet">
          {PROJECT_MEMBER_MANAGEMENT_SUBLABEL[v]}
        </div>
      </div>
    ),
  }));

  const memberViewOptions = Object.values(PROJECT_MEMBER_VIEW).map(v => ({
    value: v,
    text: (
      <div>
        {PROJECT_MEMBER_VIEW_LABEL[v]}
        <div className="discreet">
          {PROJECT_MEMBER_VIEW_SUBLABEL[v]}
        </div>
      </div>
    ),
  }));

  const advancedOptions = (
    <React.Fragment>
      <DatePickerField
        name="started_at"
        label="Start Date"
        placeholder="Choose date"
        timeFormat={false}
        sublabel="Set the start date if the project is already in progress. This will allow the providers to create a worksheet with a past billing period."
      />

      {isBudgetAllocEnabled && (
        <div className="col-12 col-md-8 col-lg-4 px-0">
          <MoneyInputField
            decimals={2}
            name="allocated_budget"
            prefix={managerBudget.currency}
            label="Budget"
            placeholder="0.00"
            sublabel={budgetSublabel}
          />
        </div>
      )}

      <TextInputField
        name="external_project_id"
        label="External project ID"
        sublabel="Assign a project reference that is unique to your organisation"
      />

      <TagsInputField
        name="clients"
        label="Client names"
        size="extra-large"
      />

      <TagsInputField
        name="tags"
        label="Extra tags"
        size="extra-large"
      />
    </React.Fragment>
  );

  const { values } = form.getState();
  const { currency } = values;
  const rateGuideUnit = values.rate_guide_unit ? parseInt(values.rate_guide_unit, 10) : null;
  const rateUnitFormat = rateGuideUnit ? RATE_UNIT_FORMAT[rateGuideUnit] : null;

  const settingsOptions = (
    <div className="row">
      {activeOrg.project_opportunities_enabled && (
        <div className="col-12">
          <RadioField
            name="visibility"
            disabled={!canChangeProjectVisibility}
            label="Project visibility"
            options={visibilityOptions}
            value={projectVisibility}
          />
        </div>
      )}

      {projectVisibility === PROJECT_VISIBILITY.ORG_ONLY && (
        <div className="col-12">
          <UserAndGroupSearchField
            excludeManagers
            label="Target to specific groups and/or providers"
            name="usersOrGroups"
            sublabel={(
              'You can select specific groups and/or providers that will be able to see this '
              + "opportunity, others won't be able to"
            )}
          />
          <InputNumberField
            label="Max number of applicants"
            name="max_applicants"
            placeholder="e.g 20"
          />
          <b>Provider rate guidance</b>
          <br />
          <div className="hint mb-5">
            Set rate guidance for Providers to use when applying for this opportunity
          </div>
          <SelectField
            label="Rate unit"
            name="rate_guide_unit"
            optionsMapping={RATE_UNIT_SELECT}
          />
          <div className="mb-5">Rate range guide</div>
          <RadioField
            name="rateGuidanceType"
            options={[
              { text: 'Range', value: RATE_GUIDANCE_TYPE.RANGE },
              { text: 'Fixed', value: RATE_GUIDANCE_TYPE.FIXED },
            ]}
            showInline
          />
          {values.rateGuidanceType === RATE_GUIDANCE_TYPE.RANGE && (
            <div className="row">
              <div className="col-12 col-md-6">
                <RateField
                  currency={currency}
                  label="Minimum"
                  name="rate_guide_minimum"
                  required={false}
                  unit={rateGuideUnit}
                />
              </div>
              <div className="col-12 col-md-6">
                <RateField
                  currency={currency}
                  label="Maximum"
                  name="rate_guide_maximum"
                  required={false}
                  unit={rateGuideUnit}
                />
              </div>
            </div>
          )}
          {values.rateGuidanceType === RATE_GUIDANCE_TYPE.FIXED && (
            <RateField
              currency={currency}
              label={rateUnitFormat?.title || 'Fixed rate'}
              name="rate_guide_fixed"
              required={false}
              unit={rateGuideUnit}
            />
          )}
        </div>
      )}

      <div className="col-12">
        <RadioField
          name="member_management"
          disabled={!enableEditingMemberSettings}
          label="Who can manage project members?"
          options={memberManagementOptions}
        />
      </div>

      <div className="col-12">
        <RadioField
          name="member_view"
          label="Who can access the project team page?"
          options={memberViewOptions}
        />
      </div>
    </div>
  );

  return (
    <React.Fragment>
      { submitting && <LoadingComponent /> }

      <form onSubmit={handleSubmit} className="signup-form">
        <div className={!embedded ? 'rounded shadow-sm p-4 bg-white' : ''}>
          <div className="clearfix">
            <div className={embedded ? 'col-sm-8 m-auto' : ''}>
              <Field type="hidden" name="id" component="input" />
              <TextInputField
                name="title"
                type="text"
                label="Project title"
                placeholder="e.g Landing page design"
                required
              />

              <div className="row">
                <div className="col-12 col-md-6 col-lg-4">
                  <DatePickerField
                    name="deadline"
                    sublabel="Leave this field blank in case of on-going projects"
                    label="Deadline"
                    disablePastDates
                  />
                </div>
              </div>

              <TextAreaField
                name="brief"
                label="Project Brief"
                sublabel="Provide a more detailed description"
                mdEditorEnabled
                height={230}
                required
              />

              <FileUploaderDirectField
                accept={PROJECT_DOCUMENTS_ALLOWED_MIMETYPES}
                allowMultiple
                label="Supported documents"
                maxFiles={MAX_UPLOAD_FILES}
                name="attachments"
              />

              <SkillSelectField
                name="skills"
                label="Skills"
                modalHeading="Project skills"
                memberSkillsOnly={false}
              />

              {/* we don't allow the syncing features because the product is a binding contract */}
              <CustomFieldsUpdater
                form={form}
                templates={customFieldTemplates}
                entityId={projectId}
                entityType={FIELD_ENTITY_TYPE.PROJECT}
                allowTemplateSelection={allowTemplateSelection}
                initialCustomFields={customFields}
                templateFieldLabel="Project template"
                enableSync={false}
                templateFieldSublabel={(
                  <p className="text-warning">
                    By selecting one or more project templates and creating a project,
                    the relevant custom fields freeze on it.
                    You will not be able to edit a project and choose other project templates.
                    <br />
                    Before you create a new project, please make sure that
                    you have selected the right project templates.
                  </p>
                )}
              />

              <AreaCollapsible
                fieldSet
                contentChildren={advancedOptions}
                headingChildren={<span>Advanced Options</span>}
              />

              <AreaCollapsible
                fieldSet
                contentChildren={settingsOptions}
                headingChildren={<span>Settings</span>}
                isExpanded={expandSettings}
              />

            </div>
          </div>
        </div>

        <div
          className={`col-12 mt-4 text-right px-0 d-flex justify-content-end ${embedded ? 'form-footer-nav' : ''}`}
        >
          <TDButton
            label="Cancel"
            onClick={() => {
              const targetUrl = editingProject
                ? projectViewUrl(orgAlias, initialValues.id)
                : projectListUrl(orgAlias);
              history.push(targetUrl);
            }}
            variant={BS_STYLE.DEFAULT}
          />

          <TDButton
            data-testid="project-form-create-project-button"
            disabled={submitting}
            label={<span>{submitBtnLabel}</span>}
            type="submit"
            variant={BS_STYLE.PRIMARY}
          />
        </div>
      </form>
    </React.Fragment>
  );
};

FormRenderer.propTypes = {
  activeOrg: orgSpec.isRequired,
  allowTemplateSelection: PropTypes.bool,
  customFields: PropTypes.arrayOf(customFieldSpec),
  customFieldTemplates: PropTypes.arrayOf(customFieldTemplateSpec),
  embedded: PropTypes.bool,
  enableBudgetAllocation: PropTypes.bool,
  enableEditingMemberSettings: PropTypes.bool,
  expandSettings: PropTypes.bool,
  form: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  initialValues: PropTypes.object,
  managerBudget: PropTypes.object,
  orgAlias: PropTypes.string,
  projectId: PropTypes.number,
  submitLabel: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
};

FormRenderer.defaultProps = {
  allowTemplateSelection: true,
  customFields: [],
  customFieldTemplates: [],
  embedded: false,
  enableBudgetAllocation: true,
  enableEditingMemberSettings: true,
  expandSettings: false,
  form: {},
  initialValues: {},
  managerBudget: {},
  orgAlias: '',
  projectId: null,
  submitLabel: '',
};

// eslint-disable-next-line react/no-multi-comp
class ProjectForm extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    const { orgAlias } = params;

    return Promise.all([
      dispatch(
        fetchActiveManagerBudgetDS({ orgAlias, authedAxios, url, componentName }),
      ),
    ]);
  }

  static GetComponentName() {
    return 'ProjectCreateForm';
  }

  constructor(props) {
    super(props);
    this.props = props;

    this.hasSkills = false;

    this.submit = this.submit.bind(this);
  }

  async submit(values) {
    const { orgAlias, onSubmitSuccess, match: { params: { id: projectId } } } = this.props;
    const isUpdate = !!projectId;

    if (isUpdate || values.visibility !== PROJECT_VISIBILITY.ORG_ONLY) {
      delete values.max_applicants; // eslint-disable-line no-param-reassign
    }

    if (isUpdate) {
      // These are needed for the form UI, but not when updating a project
      delete values.id; // eslint-disable-line no-param-reassign
      delete values.visibility; // eslint-disable-line no-param-reassign
    }

    const formData = new FormData();
    const customFieldAnswers = {};
    let hasCustomFieldAnswers = false;
    const userIds = new Set();
    const userGroupIds = new Set();
    if (values.rateGuidanceType === RATE_GUIDANCE_TYPE.RANGE) {
      delete values.rate_guide_fixed; // eslint-disable-line no-param-reassign
    } else {
      delete values.rate_guide_minimum; // eslint-disable-line no-param-reassign
      delete values.rate_guide_maximum; // eslint-disable-line no-param-reassign
    }
    Object.keys(values).forEach(key => {
      if (['rateGuidanceType', 'currency'].includes(key)) {
        return;
      }
      const value = values[key];
      if ([
        'clients', 'custom_field_ids', 'custom_field_templates', 'skills', 'tags',
      ].includes(key)) {
        formData.append(key, JSON.stringify(value || []));
        return;
      }
      if (key.startsWith(PATH_PREFIX)) {
        customFieldAnswers[key] = value;
        hasCustomFieldAnswers = true;
        return;
      }
      if (key === 'attachments' && Array.isArray(value) && value.length > 0) {
        value.forEach(attachment => {
          formData.append('attachments[]', attachment);
        });
        return;
      }
      if (key === 'usersOrGroups') {
        value.forEach(entry => {
          (entry.isGroup ? userGroupIds : userIds).add(entry.value);
        });
        return;
      }
      formData.append(key, value);
    });
    if (hasCustomFieldAnswers) {
      formData.append('customFieldAnswers', JSON.stringify(customFieldAnswers));
    }
    if (userIds.size > 0) {
      formData.append('userIds', JSON.stringify([...userIds]));
    }
    if (userGroupIds.size > 0) {
      formData.append('userGroupIds', JSON.stringify([...userGroupIds]));
    }

    try {
      let response = null;
      if (isUpdate) {
        response = await updateProjectDS(orgAlias, projectId, formData);
      } else {
        response = await createProjectDS(orgAlias, formData);
      }
      if (response.data._error) {
        return response.data;
      }
      if (onSubmitSuccess) {
        onSubmitSuccess(response.data);
      }
      return response.data;
    } catch (e) {
      return e.response?.data;
    }
  }

  render() {
    const { initialValues, ...passThroughProps } = this.props;
    return (
      <TDApiConnected
        duck="view"
        component={this.constructor}
        skeletonComponent={ProjectListSkeleton}
      >
        <Form
          initialValues={initialValues}
          keepDirtyOnReinitialize // important: mandatory templates will not work properly otherwise
          mutators={{ ...arrayMutators }}
          onSubmit={this.submit}
          render={FormRenderer}
          {...passThroughProps}
        />
      </TDApiConnected>
    );
  }
}

ProjectForm.propTypes = {
  activeOrg: orgSpec.isRequired,
  embedded: PropTypes.bool,
  enableBudgetAllocation: PropTypes.bool,
  expandSettings: PropTypes.bool,
  initialValues: PropTypes.object,
  history: routerHistorySpec.isRequired,
  managerBudget: PropTypes.object,
  match: routerMatchContentsSpec.isRequired,
  onSubmitSuccess: PropTypes.func,
  orgAlias: PropTypes.string,
  submitLabel: PropTypes.string,
};

ProjectForm.defaultProps = {
  embedded: false,
  enableBudgetAllocation: true,
  expandSettings: false,
  initialValues: {},
  managerBudget: {},
  onSubmitSuccess: undefined,
  orgAlias: '',
  submitLabel: '',
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  managerBudget: getViewState(state, ProjectForm.GetComponentName()).item,
});

const ProjectCreateFormReduxConnect = connect(mapStateToProps)(ProjectForm);

export default withRouter(ProjectCreateFormReduxConnect);
