import React from 'react';
import { Card } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';

import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import RatingScore from 'core/assets/js/components/RatingScore.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, DATETIME_FORMAT_ISO, IMG_SIZE, USER_TYPE } from 'core/assets/js/constants';
import { getDatetime, parseDate } from 'core/assets/js/lib/utils';
import { orgUserProfileUrl } from 'people/urls';
import {
  PROJECT_APPLICATION_STATUS_CLASS, PROJECT_APPLICATION_STATUS_LABEL,
} from 'projects/assets/js/constants';
import {
  projectApplicationWithUserCardSpec, projectSpec,
} from 'projects/assets/js/lib/objectSpecs';
import { projectApplicationViewUrl } from 'projects/urls';
import RateAmount from 'rates/assets/js/components/RateAmount.jsx';

const ProjectApplicationCard = ({
  item: {
    createdAt,
    id,
    rateAmount,
    status,
    userCard: {
      numReviews,
      rating,
      user: { profile: { avatar, jobTitle, name } },
    },
    userId,
  },
  project: { currency_symbol: currencySymbol, id: projectId, rateGuideUnit },
}) => {
  const history = useHistory();
  const { orgAlias } = useParams();

  const applicationUrl = projectApplicationViewUrl(orgAlias, projectId, id);

  const createdAtParsed = parseDate(createdAt, DATETIME_FORMAT_ISO);
  const ageInDays = getDatetime().diff(createdAtParsed, 'days');

  return (
    <Card className="project-opportunities-card">
      <div className="row p-4">
        <Link
          className="col-12 col-md-4 d-flex align-items-center justify-content-start"
          to={orgUserProfileUrl(orgAlias, USER_TYPE.PROVIDER, userId)}
        >
          <ProfilePic alt={name} size={[IMG_SIZE.MEDIUM, IMG_SIZE.MEDIUM]} url={avatar} />
          <div className="d-flex flex-column ml-5 provider-details">
            <div className="provider-name font-weight-bold d-flex align-items-center">
              <div>{name}</div>
              {ageInDays <= 3 && <div className="new py-1 px-2 ml-3">New</div>}
            </div>
            {jobTitle && (
              <div className="user-item__extra truncate" title={jobTitle}>{jobTitle}</div>
            )}
            <div className="user-item__extra">
              {`Applied ${createdAtParsed.fromNow()}`}
            </div>
          </div>
        </Link>
        <Link
          className={(
            'col-12 col-md-3 d-flex align-items-center justify-content-start '
            + 'justify-content-md-center my-5 my-md-0'
          )}
          to={applicationUrl}
        >
          <StatusTag
            label={PROJECT_APPLICATION_STATUS_LABEL[status]}
            statusClass={PROJECT_APPLICATION_STATUS_CLASS[status]}
          />
        </Link>
        <Link
          className={(
            'col-12 col-md-5 d-flex align-items-center justify-content-start '
            + 'justify-content-md-end score-amount-view'
          )}
          to={applicationUrl}
        >
          <RatingScore classNames="discreet mr-5" numReviews={numReviews} rating={rating} />
          {rateAmount && rateGuideUnit && (
            <div className="rate p-3 mr-5">
              <RateAmount
                amount={rateAmount}
                showRateUnitDiscreetly
                unit={rateGuideUnit}
                symbol={currencySymbol}
              />
            </div>
          )}
          <TDButton
            label="View"
            onClick={() => {
              history.push(applicationUrl);
            }}
            variant={BS_STYLE.PRIMARY}
          />
        </Link>
      </div>
    </Card>
  );
};

ProjectApplicationCard.propTypes = {
  item: projectApplicationWithUserCardSpec.isRequired,
  project: projectSpec.isRequired,
};

export default ProjectApplicationCard;
