import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  PROJECT_VISIBILITY, PROJECT_MEMBER_MANAGEMENT, PROJECT_MEMBER_VIEW, RATE_GUIDANCE_TYPE,
} from 'projects/assets/js/constants';
import { customFieldTemplateSpec } from 'interviews/assets/js/lib/objectSpecs';
import { getCustomFieldTemplates, fetchCustomFieldTemplatesDS } from 'interviews/assets/js/ducks/customFields';
import { extractCustomFieldSetup } from 'interviews/assets/js/lib/utils';
import { FIELD_ENTITY_TYPE } from 'interviews/assets/js/constants';
import { projectListUrl, projectViewUrl, projectOpportunitiesListUrl } from 'projects/urls';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import ProjectForm from 'projects/assets/js/components/ProjectForm.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';

class ProjectCreateView extends React.Component {
  static FetchData({ dispatch, params, authedAxios, url }) {
    const { orgAlias } = params;
    return Promise.all([
      dispatch(fetchCustomFieldTemplatesDS({
        orgAlias,
        entityType: FIELD_ENTITY_TYPE.PROJECT,
        authedAxios,
        url,
      })),
    ]);
  }

  static GetComponentName() {
    return 'ProjectCreateView';
  }

  constructor(props) {
    super(props);

    this.handleSubmitSuccess = this.handleSubmitSuccess.bind(this);
  }

  handleSubmitSuccess(response) {
    const { history, match: { params: { orgAlias } } } = this.props;

    if (response.project && response.project.id) {
      history.push(projectViewUrl(orgAlias, response.project.id));
    } else {
      history.push(projectListUrl(orgAlias));
    }
  }

  render() {
    const {
      match: { params: { orgAlias } },
      organization, location, customFieldTemplates,
    } = this.props;

    const isOpportunity = !!queryString.parse(location.search).isOpportunity;

    const breadcrumbs = [
      isOpportunity
        ? { title: 'Opportunities', url: projectOpportunitiesListUrl(orgAlias) }
        : { title: 'Projects', url: projectListUrl(orgAlias) },
      { title: isOpportunity ? 'Create opportunity' : 'Create new project', url: null },
    ];

    const visibility = isOpportunity
      ? PROJECT_VISIBILITY.ORG_ONLY
      : PROJECT_VISIBILITY.INVITATION_ONLY;

    const {
      initialValues: customFieldInitialValues,
      selectedCustomFields,
    } = extractCustomFieldSetup({ templates: customFieldTemplates });

    const initialValues = {
      currency: organization.currency,
      visibility,
      member_management: PROJECT_MEMBER_MANAGEMENT.ALL_MANAGERS,
      member_view: organization.allow_providers_access_team_page
        ? PROJECT_MEMBER_VIEW.ALL_MEMBERS
        : PROJECT_MEMBER_VIEW.MANAGERS_ONLY,
      rateGuidanceType: RATE_GUIDANCE_TYPE.RANGE,
      ...customFieldInitialValues,
    };

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} />

        <div className="page page--project-create">
          <div className="container">
            <TDApiConnected
              duck="list"
              component={this.constructor}
            >
              <ProjectForm
                initialValues={initialValues}
                expandSettings={isOpportunity}
                onSubmitSuccess={this.handleSubmitSuccess}
                orgAlias={orgAlias}
                customFields={selectedCustomFields}
                customFieldTemplates={customFieldTemplates}
                enableEditingMemberSettings
                allowTemplateSelection
              />
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ProjectCreateView.propTypes = {
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
  organization: orgSpec.isRequired,
  customFieldTemplates: PropTypes.arrayOf(customFieldTemplateSpec),
};

ProjectCreateView.defaultProps = {
  customFieldTemplates: [],
};

const mapStateToProps = state => ({
  customFieldTemplates: getCustomFieldTemplates(state),
  organization: selectActiveOrg(state),
});

const ProjectCreateViewConnect = connect(mapStateToProps)(ProjectCreateView);
export default withRouter(ProjectCreateViewConnect);
